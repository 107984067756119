<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-30">
        <div class="card-header">
          <div class="row w-100">
            <div class="col-8">
              <h5 class="main-card-title">{{ $t("View") }}</h5>
            </div>
            <div class="col-4 d-flex">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('search')"
                  v-model="keyword"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    id="button-addon-group"
                    @click="search"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
              <button
                class="btn btn-outline-secondary reset-button"
                @click="clearSearch"
              >
                {{ $t("reset") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div
              v-if="loading"
              class="d-flex justify-content-center"
              style="height: 100vh"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table
              class="table foo-filtering-table"
              data-filtering="true"
              v-else
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("workerName") }}
                    <span
                      class="sorting"
                      :class="{
                        'sort-up':
                          selectedField === 'worker_name' &&
                          sortKeys.worker_name,
                        'sort-down':
                          selectedField === 'worker_name' &&
                          !sortKeys.worker_name,
                      }"
                      @click="sortData('worker_name')"
                    ></span>
                  </th>
                  <th>{{ $t("image") }}</th>
                  <th>
                    {{ $t("fieldName") }}
                  </th>
                  <th>{{ $t("location") }}</th>
                  <th style="min-width: 70px">
                    {{ $t("HeartRate") }}
                    <span
                      class="sorting"
                      :class="{
                        'sort-up':
                          selectedField === 'heart_rate' && sortKeys.heart_rate,
                        'sort-down':
                          selectedField === 'heart_rate' &&
                          !sortKeys.heart_rate,
                      }"
                      @click="sortData('heart_rate')"
                    ></span>
                  </th>
                  <th style="min-width: 54px">
                    {{ $t("BodyTemperature") }}
                    <span
                      class="sorting"
                      :class="{
                        'sort-up':
                          selectedField === 'body_temperature' &&
                          sortKeys.body_temperature,
                        'sort-down':
                          selectedField === 'body_temperature' &&
                          !sortKeys.body_temperature,
                      }"
                      @click="sortData('body_temperature')"
                    ></span>
                  </th>
                  <th style="min-width: 80px">
                    {{ $t("FieldTemperature") }}
                  </th>
                  <th>{{ $t("remarks") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="worker of workersList" :key="worker.id" v-bind:style= "[worker.heart_rate >= 120 ? {'background-color': 'red'} : {'background-color': 'none'}]">
                  <td class="hover">
                    <router-link
                      :to="`/${role}/worker-management/${worker.id}/worker-details`"
                    >
                      {{ worker.worker_name }}
                    </router-link>
                  </td>
                  <td>
                    <img
                      v-if="!worker.image"
                      src="/assets/images/users/profile.svg"
                      alt=""
                    /><img
                      v-else
                      :src="worker.image"
                      alt=""
                      class="img-fluid rounded-circle"
                    />
                  </td>
                  <td>
                    <template
                      v-for="(field, index) in worker.fields"
                      :key="index"
                    >
                      {{
                        index != worker.fields.length - 1
                          ? `${field.field_name}, `
                          : field.field_name
                      }}
                    </template>
                  </td>
                  <td>{{ worker.location }}</td>
                  <td>
                    <span>{{ worker.heart_rate }}</span>
                  </td>
                  <td>{{ worker.body_temperature }}</td>
                  <td>
                    <span
                      v-for="(field, index) in worker.fields"
                      :key="index"
                      >{{
                        index != worker.fields.length - 1
                          ? `${field.field_temperature}, `
                          : field.field_temperature
                      }}</span
                    >
                  </td>
                  <td>{{ worker.remarks }}</td>
                  <td>
                    <div class="btn-group btn-group-sm" style="float: none">
                      <router-link
                        v-if="role === 'admin'"
                        :to="{
                          name: 'WorkerAdminFormEdit',
                          params: { id: worker.id },
                        }"
                        class="
                          btn btn-sm btn-outline-primary
                          rounded
                          d-flex
                          align-items-center
                        "
                        style="float: none; margin: 5px"
                        ><span class="ti-pencil"></span
                      ></router-link>
                      <router-link
                        v-if="role === 'client'"
                        :to="{
                          name: 'WorkerClientFormEdit',
                          params: { id: worker.id },
                        }"
                        class="
                          btn btn-sm btn-outline-primary
                          rounded
                          d-flex
                          align-items-center
                        "
                        style="float: none; margin: 5px"
                        ><span class="ti-pencil"></span
                      ></router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-danger rounded"
                        style="float: none; margin: 5px"
                        @click="removeWorker(worker.id, worker.worker_name)"
                      >
                        <span class="ti-trash"></span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div class="footable-paging">
          <div>
            <div
              class="footable-pagination-wrapper d-flex justify-content-center"
            >
              <ul class="pagination">
                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-if="pagination.previousPageUrl"
                >
                  <a class="footable-page-link btn" @click="getPrevPageData()"
                    >‹</a
                  >
                </li>

                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-else
                >
                  <a class="footable-page-link text-secondary btn">‹</a>
                </li>
                <li
                  class="footable-page visible"
                  :class="{ active: pagination.activePage == page }"
                  v-for="(page, index) of totalPages"
                  :key="index"
                >
                  <a
                    class="footable-page-link btn"
                    @click="getPageData(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  class="footable-page-nav ml-4"
                  v-if="pagination.nextPageUrl"
                >
                  <a class="footable-page-link btn" @click="getNextPageData()"
                    >›</a
                  >
                </li>
                <li class="footable-page-nav ml-4" v-else>
                  <a class="footable-page-link btn text-secondary">›</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect, computed } from "vue";
import { useRouter } from "vue-router";
import { get } from "../../core/api/api-service";
import { store } from "../../core/store";
import constants from "../../core/constants";
import config from "../../core/config";

const Pusher = require("pusher-js");

export default {
  name: "WorkerTable",
  setup() {
    let role = store.getters.role;
    const router = useRouter();
    const workersList = ref([]);
    const pagination = ref({
      totalCount: null,
      perPage: "20",
      currentPage: 1,
      nextPageUrl: "",
      activePage: 1,
      previousPageUrl: "",
    });
    const url = ref("");
    const keyword = ref("");
    const sortKeys = ref({
      worker_name: false,
      body_temperature: false,
      heart_rate: false,
    });
    const selectedField = ref("");

    const loading = ref(true);

    const setRole = () => {
      if (role === "admin") {
        url.value = constants.workerAdminUrl;
      } else {
        url.value = constants.workerClientUrl;
      }
    };

    const addSerialNo = () => {
      for (let i = 0; i < workersList.value.length; i++) {
        workersList.value["sn"] = null;
        workersList.value[i].sn =
          i + 1 + pagination.value.perPage * (pagination.value.activePage - 1);
      }
    };

    onMounted(async () => {
      setRole();
      getWorkers();
      listenWorkerDataChange();
    });

    const listenWorkerDataChange = () => {
      const pusher = new Pusher(config.pusherId, {
        cluster: config.pusherCluster,
      });
      const channel = pusher.subscribe("heart-rate");
      channel.bind("heart-rate", function (data) {
        if (data) {
          getWorkers();
        }
      });
    };

    const getPrevPageData = () => {
      if (pagination.value.previousPageUrl) {
        get(
          `${pagination.value.previousPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "worker_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          workersList.value = res.data.data;
          pagination.value.activePage--;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const getPageData = (page) => {
      pagination.value.activePage = page;
      if (role === "admin") {
        get(
          `/api/v1/admin/workers?page=${pagination.value.activePage}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "worker_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          workersList.value = res.data.data;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          pagination.value.currentPage = res.data.paginationData.currentPage;
          addSerialNo();
          loading.value = false;
        });
      } else {
        get(
          `/api/v1/client/workers?page=${pagination.value.activePage}&perPage=${
            pagination.value.perPage
          }&column=${selectedField?.value ? selectedField.value : ""}&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          workersList.value = res.data.data;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const getNextPageData = () => {
      if (pagination.value.nextPageUrl) {
        get(
          `${pagination.value.nextPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "worker_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          workersList.value = res.data.data;
          pagination.value.activePage++;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const totalPages = computed(() => {
      return Math.ceil(pagination.value.totalCount / pagination.value.perPage);
    });

    const getWorkers = () => {
      get(`${url.value}?perPage=${pagination.value.perPage}`).then((res) => {
        workersList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        loading.value = false;
      });
    };

    const clearSearch = () => {
      keyword.value = "";
      getWorkers();
    };

    const goToWorkerDetails = (id) => {
      router.push({
        name: "WorkerDetails",
        query: {
          id: id,
        },
      });
    };

    watchEffect(() => {
      store.getters.modalMessage.success;
      if (store.getters.role === "admin") {
        if (store.getters.modalMessage.success === true) {
          get(`/api/v1/admin/workers?perPage=${pagination.value.perPage}`).then(
            (res) => {
              workersList.value = res.data.data;
              pagination.value.currentPage = 1;
              pagination.value.activePage = 1;
              addSerialNo();
              loading.value = false;
            }
          );
        }
      }
      if (store.getters.role === "client") {
        if (store.getters.modalMessage.success === true) {
          get(
            `/api/v1/client/workers?perPage=${pagination.value.perPage}`
          ).then((res) => {
            workersList.value = res.data.data;
            pagination.value.currentPage = 1;
            pagination.value.activePage = 1;
            addSerialNo();
            loading.value = false;
          });
        }
      }
    });

    const removeWorker = async (workerId, workerName) => {
      await store.dispatch("updateModalMessage", {
        userName: workerName,
        userId: workerId,
        modalInactive: false,
        page: pagination.value.perPage,
      });
    };

    const search = () => {
      loading.value = true;
      get(
        `${url.value}?perPage=${pagination.value.perPage}&keyword=${keyword.value}`
      ).then((res) => {
        workersList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        loading.value = false;
      });
    };

    // const debounce = (func, timeout = 300) => {
    //   let timer;
    //   return (...args) => {
    //     clearTimeout(timer);
    //     timer = setTimeout(() => {
    //       func.apply(this, args);
    //     }, timeout);
    //   };
    // };

    // const debouncedSearch = debounce(() => search());

    const sortData = (sortKey) => {
      selectedField.value = sortKey;
      loading.value = true;
      for (let key in sortKeys.value) {
        if (key === sortKey) {
          sortKeys.value[key] = !sortKeys.value[key];
        } else {
          sortKeys.value[key] = false;
        }
      }
      let workerUrl =
        store.getters.role === "admin"
          ? `/api/v1/admin/workers?page=${pagination.value.currentPage}&perPage=${pagination.value.perPage}`
          : `/api/v1/client/workers?page=${pagination.value.currentPage}&perPage=${pagination.value.perPage}`;
      if (sortKeys.value.worker_name) {
        workerUrl += `&column=id&asc=${sortKeys.value.worker_name}`;
      }
      if (sortKeys.value.heart_rate) {
        workerUrl += `&column=heart_rate&asc=${sortKeys.value.heart_rate}`;
      }
      if (sortKeys.value.body_temperature) {
        workerUrl += `&column=body_temperature&asc=${sortKeys.value.body_temperature}`;
      }
      if (
        !sortKeys.value.worker_name &&
        !sortKeys.value.body_temperature &&
        !sortKeys.value.heart_rate
      ) {
        workerUrl += `&column=${sortKey === "worker_name" ? "id" : sortKey}`;
      }
      get(workerUrl).then((res) => {
        workersList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        loading.value = false;
      });
    };

    return {
      workersList,
      removeWorker,
      pagination,
      keyword,
      getPrevPageData,
      getPageData,
      getNextPageData,
      goToWorkerDetails,
      totalPages,
      role,
      addSerialNo,
      loading,
      search,
      sortKeys,
      selectedField,
      sortData,
      clearSearch,
    };
  },
};
</script>

<style scoped>
@import "../../assets/custom/custom-table.css";
</style>

<style lang="scss" scoped>
td {
  &.hover:hover {
    cursor: pointer;
  }
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #e5e5e5;
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  -webkit-border-radius: 1ex;
}
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
</style>
