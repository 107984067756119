<template>
  <div
    class="position-fixed vw-100 vh-100"
    :class="{ 'd-none': modalInactive }"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style="background: rgba(0, 0, 0, 0.5); z-index: 10"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-danger border-0">
          <h5 class="modal-title" id="exampleModalCenterTitle">
            <span
              v-if="
                breadcrumbs == 'ClientFieldManagement' ||
                breadcrumbs == 'AdminFieldManagement'
              "
            >
              {{ $t("removeField") }}
            </span>
            <span
              v-if="
                breadcrumbs == 'ClientWorkerManagement' ||
                breadcrumbs == 'AdminWorkerManagement'
              "
            >
              {{ $t("deleteWorker") }}
            </span>
            <span v-if="breadcrumbs == 'ClientManagement'">
              {{ $t("deleteClient") }}
            </span>
            <span v-if="breadcrumbs == 'AdminManagement'">
              {{ $t("deleteAdmin") }}
            </span>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span v-if="!errors.length">
            <p
              class="text-muted"
              v-if="
                breadcrumbs === 'ClientFieldManagement' ||
                breadcrumbs === 'AdminFieldManagement'
              "
            >
              {{ $t("areYouSure") }}
              <span class="text-primary" style="overflow-wrap: break-word">
                {{ getuserName }} </span
              >{{ $t("youWantToDeleteField") }}
            </p>
            <p class="text-muted" v-else>
              {{ $t("areYouSure") }}
              <span class="text-primary" style="overflow-wrap: break-word">
                {{ getuserName }} </span
              >{{ $t("youWantToDelete") }}
            </p>
          </span>
          <p v-for="error of errors" :key="error" class="text-danger">
            {{ $t(error.detail) }}
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'AdminManagement'"
            @click="removeAdmin"
          >
            {{ $t("delete") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'ClientManagement'"
            @click="removeClient"
          >
            {{ $t("delete") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'AdminFieldManagement'"
            @click="removeField"
          >
            {{ $t("delete") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'ClientFieldManagement' && role === 'client'"
            @click="removeFieldByClient"
          >
            {{ $t("delete") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'AdminWorkerManagement' && role === 'admin'"
            @click="removeWorkerByAdmin"
          >
            {{ $t("delete") }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            v-if="breadcrumbs === 'ClientWorkerManagement' && role === 'client'"
            @click="removeWorkerByClient"
          >
            {{ $t("delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue";
import { store } from "../../core/store";
import { toastMessage } from "../../core/utils";
import { get, remove } from "../../core/api/api-service";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/i18n";

export default {
  name: "Modal",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const adminsList = ref([]);
    const modalInactive = ref(true);
    const getUserId = ref("");
    const getuserName = ref("");
    const getPage = ref("");
    const currentRoute = ref("");
    const role = store.getters.role;
    const errors = ref([]);
    const breadcrumbs = ref("");

    watchEffect(() => {
      if (store.getters.modalMessage.userId) {
        modalInactive.value = store.getters.modalMessage.modalInactive;
        getUserId.value = store.getters.modalMessage.userId;
        getuserName.value = store.getters.modalMessage.userName;
        getPage.value = store.getters.modalMessage.page;
      }
      if (store.getters.authenticated) {
        if (route.name !== "Home") {
          breadcrumbs.value = route.meta.breadcrumb[1].pathName;
        } else {
          breadcrumbs.value = "";
        }
      }
    });

    const closeModal = () => {
      modalInactive.value = true;
      errors.value = [];
      store.dispatch("updateModalMessage", {
        modalInactive: true,
      });
    };

    onMounted(() => {
      currentRoute.value = router.currentRoute.value.name;
      if (currentRoute.value === "AdminManagement") {
        get(`/api/v1/admins?perPage=${getPage.value}`).then((res) => {
          adminsList.value = res.data.data;
        });
      }
    });

    const removeAdmin = () => {
      errors.value = [];
      remove(`/api/v1/admins/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/admins?perPage=${getPage.value}`)
              .then(() => {
                modalInactive.value = true;
                store.dispatch("updateModalMessage", {
                  modalInactive: true,
                });
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Admin successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          errors.value = err.response.data.errors;
        });
    };

    const updateModalStatus = () => {
      modalInactive.value = true;
      store.dispatch("updateModalMessage", {
        modalInactive: true,
      });
    };

    const removeClient = () => {
      errors.value = [];
      remove(`/api/v1/clients/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/clients?perPage=${getPage.value}`)
              .then(() => {
                updateModalStatus();
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Client successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          errors.value = err.response.data.errors;
        });
    };

    const removeField = () => {
      errors.value = [];
      remove(`/api/v1/admin/fields/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/admin/fields?perPage=${getPage.value}`)
              .then(() => {
                updateModalStatus();
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Field successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          errors.value = err.response.data.errors;
        });
    };

    const removeFieldByClient = () => {
      errors.value = [];
      remove(`/api/v1/client/fields/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/client/fields?perPage=${getPage.value}`)
              .then(() => {
                updateModalStatus();
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Field successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          errors.value = err.response.data.errors;
        });
    };

    const removeWorkerByAdmin = () => {
      errors.value = [];
      remove(`/api/v1/admin/workers/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/admin/workers?perPage=${getPage.value}`)
              .then(() => {
                updateModalStatus();
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Worker successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          errors.value = err.response.data.errors;
        });
    };

    const removeWorkerByClient = () => {
      remove(`/api/v1/client/workers/${getUserId.value}`)
        .then((res) => {
          if (res.status === 200) {
            get(`/api/v1/client/workers?perPage=${getPage.value}`)
              .then(() => {
                updateModalStatus();
              })
              .then(() => {
                toastMessage(
                  i18n.global.t("Success"),
                  i18n.global.t("Worker successfully deleted.")
                );
              })
              .then(() => {
                store.dispatch("updateModalMessage", {
                  success: true,
                });
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    return {
      closeModal,
      modalInactive,
      getUserId,
      getuserName,
      removeAdmin,
      removeClient,
      removeField,
      removeFieldByClient,
      removeWorkerByAdmin,
      removeWorkerByClient,
      getPage,
      currentRoute,
      role,
      errors,
      breadcrumbs,
    };
  },
};
</script>