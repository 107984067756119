<template>
  <div>
    <div id="containerbar">
      <InfoBarRight />
      <Sidebar />
      <Modal />
      <div class="rightbar">
        <Toast />
        <Header />
        <Breadcrumb />
        <div class="contentbar">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/common/Sidebar.vue";
import InfoBarRight from "../components/common/InfoBarRight.vue";
import Toast from "../components/common/Toast.vue";
import Modal from "../components/common/Modal.vue";
import Header from "../components/common/Header.vue";
import Breadcrumb from "../components/common/Breadcrumb.vue";
import Footer from "../components/common/Footer.vue";
export default {
  name: "Home",
  components: {
    Sidebar,
    InfoBarRight,
    Toast,
    Modal,
    Header,
    Breadcrumb,
    Footer,
  },
};
</script>
