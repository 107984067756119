<template>
  <AuthLayout>
    <form @submit.prevent="handleSubmit">
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="username"
            :placeholder="$t('loginId')"
            v-model="formValues.name"
          />
          <div class="text-left mb-3" v-if="errors.name">
            <span class="text-danger"> {{ errors.name }}</span>
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            id="password"
            :placeholder="$t('password')"
            v-model="formValues.password"
          />
          <div class="text-left mb-3" v-if="errors.password">
            <span class="text-danger"> {{ errors.password }}</span>
          </div>
        </div>
        <div class="text-left mb-3" v-if="errors.error">
          <span class="text-danger"> {{ errors.error }}</span>
        </div>
        <div class="form-row mb-3">
          <div class="col-sm-6">
            <div class="custom-control custom-checkbox text-left">
              <input
                type="checkbox"
                class="custom-control-input"
                id="rememberme"
              />
              <label class="custom-control-label font-14" for="rememberme">{{
                $t("rememberMe")
              }}</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="forgot-psw">
              <a id="forgot-psw" href="forgot-password" class="font-14">{{
                $t("forgotPassword")
              }}</a>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-success btn-lg btn-block font-18">
          {{ $t("login") }}
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { login, clientLogin } from "../../core/api/auth-service";
import AuthLayout from "../../components/admin/AuthLayout.vue";
import i18n from "@/i18n";
export default {
  name: "Login",
  components: {
    AuthLayout,
  },
  setup() {
    const router = useRouter();
    const errors = ref([]);
    const formValues = ref({ name: "", password: "" });

    const handleSubmit = () => {
      errors.value = [];
      if (!formValues.value.name) {
        errors.value.name = i18n.global.t("The login id field is required.");
      }
      if (!formValues.value.password) {
        errors.value.password = i18n.global.t(
          "The password field is required."
        );
      }
      if (
        formValues.value.name &&
        formValues.value.password &&
        router.currentRoute.value.name === "Login"
      ) {
        login(formValues.value)
          .then((res) => {
            if (res.status === 200) router.push({ name: "Home" });
          })
          .catch((err) => {
            errors.value.error = i18n.global.t(
              err.response.data?.errors?.[0]?.title
            );
          });
      }

      if (
        formValues.value.name &&
        formValues.value.password &&
        router.currentRoute.value.name === "ClientLogin"
      ) {
        clientLogin(formValues.value)
          .then((res) => {
            if (res.status === 200) router.push({ name: "Home" });
          })
          .catch((err) => {
            errors.value.error = i18n.global.t(
              err.response.data?.errors?.[0]?.title
            );
          });
      }
    };

    const preventBack = () => {
      window.history.forward();
    };

    onMounted(() => {
      if (
        router.currentRoute.value.name === "ClientLogin" ||
        router.currentRoute.value.name === "Login"
      ) {
        setTimeout("preventBack()", 0);

        window.onunload = function () {
          null;
        };
      }
    });

    return { errors, formValues, handleSubmit, preventBack };
  },
};
</script>