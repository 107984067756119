<template>
  <div class="leftbar">
    <!-- Start Sidebar -->
    <div class="sidebar">
      <!-- Start Logobar -->
      <div class="logobar">
        <router-link :to="{ path: '/' }" class="logo logo-large">
          <img src="/assets/logo.png" class="img-fluid w-25" alt="logo" />
        </router-link>
        <router-link :to="{ path: '/' }" class="logo logo-small">
          <img src="/assets/logo.png" class="img-fluid" alt="logo" />
        </router-link>
      </div>
      <!-- End Logobar -->
      <!-- Start Navigationbar -->
      <div class="navigationbar">
        <ul class="vertical-menu">
          <li v-if="role == 'admin'">
            <router-link
              :to="{ name: 'AdminManagement' }"
              :class="{
                'router-link-exact-active': breadcrumbs === 'AdminManagement',
              }"
            >
              <i class="ri-user-2-fill"></i>
              <span
                ><div>{{ $t("adminManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'admin'">
            <router-link
              :to="{ name: 'ClientManagement' }"
              :class="{
                'router-link-exact-active': breadcrumbs === 'ClientManagement',
              }"
            >
              <i class="ri-user-6-fill"></i>
              <span
                ><div>{{ $t("clientManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'admin'">
            <router-link
              :to="{ name: 'AdminFieldManagement' }"
              :class="{
                'router-link-exact-active':
                  breadcrumbs === 'AdminFieldManagement',
              }"
            >
              <i class="ri-layout-4-line"></i>
              <span
                ><div>{{ $t("fieldManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'admin'">
            <router-link
              :to="{ name: 'AdminWorkerManagement' }"
              :class="{
                'router-link-exact-active':
                  breadcrumbs === 'AdminWorkerManagement' ||
                  breadcrumbs === 'ClientWorkerManagement',
              }"
            >
              <i class="ri-user-star-fill"></i>
              <span
                ><div>{{ $t("workerManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'client'">
            <router-link
              :to="{ name: 'ClientFieldManagement' }"
              :class="{
                'router-link-exact-active':
                  breadcrumbs === 'ClientFieldManagement',
              }"
            >
              <i class="ri-layout-4-line"></i>
              <span
                ><div>{{ $t("fieldManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'client'">
            <router-link
              :to="{ name: 'ClientWorkerManagement' }"
              :class="{
                'router-link-exact-active':
                  breadcrumbs === 'ClientWorkerManagement' ||
                  breadcrumbs === 'AdminWorkerManagement',
              }"
            >
              <i class="ri-user-star-fill"></i>
              <span
                ><div>{{ $t("workerManagement") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'admin'">
            <router-link :to="{ name: 'LocationPage' }">
              <i class="ri-map-pin-2-fill"></i>
              <span
                ><div>{{ $t("Location") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'client'">
            <router-link :to="{ name: 'clientLocationPage' }">
              <i class="ri-map-pin-2-fill"></i>
              <span
                ><div>{{ $t("Location") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'admin'">
            <router-link :to="{ name: 'ConductorAnalysis' }">
              <i class="ri-map-pin-line"></i>
              <span
                ><div>{{ $t("conductorAnalysis") }}</div></span
              >
            </router-link>
          </li>
          <li v-if="role == 'client'">
            <router-link :to="{ name: 'ClientConductorAnalysis' }">
              <i class="ri-map-pin-line"></i>
              <span
                ><div>{{ $t("conductorAnalysis") }}</div></span
              >
            </router-link>
          </li>
        </ul>
      </div>
      <!-- End Navigationbar -->
    </div>
    <!-- End Sidebar -->
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { store } from "../../core/store";
import { useRoute } from "vue-router";

export default {
  name: "Sidebar",
  setup() {
    const role = ref("");
    const route = useRoute();
    const breadcrumbs = ref("");
    watchEffect(() => {
      if (store.getters.authenticated) {
        if (route.name !== "Home") {
          breadcrumbs.value = route.meta.breadcrumb[1].pathName;
        } else {
          breadcrumbs.value = "";
        }
        if (store.getters.role === "admin") {
          role.value = "admin";
        } else {
          role.value = "client";
        }
      }
    });
    return {
      role,
      breadcrumbs,
    };
  },
};
</script>

<style>
.vertical-menu > li > a {
  display: flex;
}
@media (min-width: 768px) {
  .vertical-layout.toggle-menu .vertical-menu > li > a {
    display: flex;
  }
  .vertical-menu > li > a > span > div {
    width: 100%;
    padding-right: 32px;
    min-width: 200px;
    margin-left: 10px;
  }
}
</style>
