export default {
  "agriHealthManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agri Health Manager"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "rememberPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Password?"])},
  "resetInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address below to receive reset password instructions."])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "adminManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Management"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "clientManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Management"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "createClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Client"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Admin"])},
  "addField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Field"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "viewAdmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Admins"])},
  "viewClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Clients"])},
  "adminName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Name"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login ID"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S.No"])},
  "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
  "removeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete User"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Client"])},
  "deleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Admin"])},
  "deleteWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Worker"])},
  "confirmRemoveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
  "fieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Management"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure"])},
  "youWantToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be deleted?"])},
  "youWantToDeleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["will be deleted?"])},
  "fieldList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field List"])},
  "adminEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Admin"])},
  "clientEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Client"])},
  "fieldEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Field"])},
  "workerEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Worker"])},
  "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
  "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
  "clientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Id"])},
  "selectClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Client Id"])},
  "selectClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Client"])},
  "selectFieldId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field Id"])},
  "selectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Field"])},
  "selectGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Gender"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
  "prefectureId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefecture Id"])},
  "prefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefecture"])},
  "selectPrefectureId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Prefecture Id"])},
  "selectPrefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Prefecture"])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address (city)"])},
  "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1 (town name / street Number)"])},
  "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2 ( apartment / building name, room number)"])},
  "address1Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "address2Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
  "address3Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
  "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
  "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area(ha)"])},
  "createField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Field"])},
  "removeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Field"])},
  "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "Admin created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin created successfully."])},
  "Admin edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin edited successfully."])},
  "Password changed successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully."])},
  "Client created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client created successfully."])},
  "Client edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client edited successfully."])},
  "Admin successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin successfully deleted."])},
  "Client successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client successfully deleted."])},
  "Field created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field created successfully."])},
  "Field edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field edited successfully."])},
  "Field successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field successfully deleted."])},
  "You can not delete your own profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not delete your own profile."])},
  "The login id must only contain letters and numbers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id must only contain letters and numbers."])},
  "The worker  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name must contain alphanumeric or japanese character."])},
  "The field  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field name must contain alphanumeric or japanese character."])},
  "The client  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client name must contain alphanumeric or japanese character."])},
  "The admin  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admin name must contain alphanumeric or japanese character."])},
  "Data not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data not found"])},
  "The prefecture title field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prefecture title field is required."])},
  "The login id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id field is required."])},
  "The password field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password field is required."])},
  "The user credentials were incorrect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user credentials were incorrect."])},
  "Please enter email address.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email address."])},
  "This email is not registered.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is not registered"])},
  "The field name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field name field is required."])},
  "The client id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client id field is required."])},
  "The postal code field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The postal code field is required."])},
  "The prefecture id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prefecture id field is required."])},
  "The address 1 field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 1 field is required."])},
  "The address 2 field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 2 field is required."])},
  "The latitude field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latitude field is required."])},
  "The longitude field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The longitude field is required."])},
  "The field name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field name must not be greater than 255 characters."])},
  "The field name format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field name format is invalid."])},
  "Field already exists for client.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field already exists for client."])},
  "The postal code must be between 7 and 8 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The postal code must be between 7 and 8 digits."])},
  "The postal code must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The postal code must be a number."])},
  "The address 1 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 1 must not be greater than 255 characters."])},
  "The address 1 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 1 format is invalid."])},
  "The address 1 must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 1 must be at least 1 characters."])},
  "The address 2 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 2 must not be greater than 255 characters."])},
  "The address 2 must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 2 must be at least 1 characters."])},
  "The address 2 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 2 format is invalid."])},
  "The address 3 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 3 must not be greater than 255 characters."])},
  "The address 3 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 3 format is invalid."])},
  "The latitude format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latitude format is invalid."])},
  "The latitude must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latitude must be a number."])},
  "The longitude format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The longitude format is invalid."])},
  "The longitude must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The longitude must be a number."])},
  "The area format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The area format is invalid."])},
  "The address 1 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 1 must contain alphanumeric or japanese character."])},
  "The address 2 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 2 must contain alphanumeric or japanese character."])},
  "The address 3 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The address 3 must contain alphanumeric or japanese character."])},
  "The admin name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admin name must not be greater than 255 characters."])},
  "The admin name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admin name field is required."])},
  "The login Id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login Id field is required"])},
  "The client name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client name field is required."])},
  "The email field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email field is required."])},
  "The password confirmation field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password confirmation field is required."])},
  "The client name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client name must not be greater than 255 characters."])},
  "The login id must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id must not be greater than 255 characters."])},
  "The remarks must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The remarks must not be greater than 255 characters."])},
  "The email must be a valid email address.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email must be a valid email address."])},
  "The password confirmation and password must match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password confirmation and password must match."])},
  "The password must contain at least one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least one symbol."])},
  "The password must not be greater than 32 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must not be greater than 32 characters."])},
  "The password must contain at least one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain atleast one uppercase letter."])},
  "The password must be at least 6 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be at least 6 characters."])},
  "The email has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email has already been taken."])},
  "The login id has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id has already been taken."])},
  "The password must be greater than 6 and less than 32 character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must be greater than 6 and less than 32 character."])},
  "The password must contain one symbol and one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passowrd must contain one symbol and one uppercase letter."])},
  "The admin name must only contain letters and numbers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The admin name must only contain letters and numbers."])},
  "Admin not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin not found . Please register to login."])},
  "Client not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client not found . Please register to login."])},
  "Worker not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker not found . Please register to login."])},
  "Password Reset Email Sent to Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset Email Sent to Admin"])},
  "Password Reset Email Sent to User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset Email Sent to Client"])},
  "Password successfully reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully reset."])},
  "Password successfully reset.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully reset."])},
  "This email is not registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is not registered"])},
  "The client is associated to field and worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client is associated to field and worker."])},
  "The client is associated to field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client is associated to field."])},
  "The client is associated to worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client is associated to worker."])},
  "This field is associated to worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is associated to worker."])},
  "The field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is required."])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Details"])},
  "fieldId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field ID"])},
  "workerManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Management"])},
  "worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker"])},
  "workerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker"])},
  "viewWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Wrokers"])},
  "addWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Worker"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height(cm)"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight(kg)"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "The worker name worker is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name worker is required."])},
  "The field id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field id worker is required."])},
  "The gender field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gender field is required."])},
  "The height field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The height field is required."])},
  "The weight field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight field is required."])},
  "The birthday field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The birthday field is required."])},
  "Worker successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker successfully deleted."])},
  "The worker name must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name must be at least 1 characters."])},
  "The worker name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name must not be greater than 255 characters."])},
  "The worker name format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name format is invalid."])},
  "This field does not belongs to client.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field does not belongs to client."])},
  "The weight must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight must be a number."])},
  "The height must be between 2 and 3 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The height must be between 2 and 3 digits."])},
  "The weight must be between 2 and 3 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight must be between 2 and 3 digits."])},
  "The birthday is not a valid date.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The birthday is not a valid date."])},
  "The birthday must be a date before today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The birthday must be a date before today."])},
  "The image must be an image.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image must be an image."])},
  "The image must be a file of type: jpeg, png, gif, svg.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image must be a file of type: jpeg, png, gif, svg."])},
  "The image must not be greater than 2048 kilobytes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image must not be greater than 2048 kilobytes."])},
  "The image has invalid image dimensions.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The image has invalid image dimensions."])},
  "The clientId field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clientId field is required."])},
  "The worker name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The worker name field is required."])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vew"])},
  "worker created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worker created successfully."])},
  "worker edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["worker edited successfully."])},
  "FieldTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Temperature"])},
  "HeartRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart Rate"])},
  "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "BodyTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Temperature"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["female"])},
  "heartRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heart Rate"])},
  "currentLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Location"])},
  "workerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worker Details"])},
  "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose File"])},
  "noFileChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No File Chosen"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "The password must contain one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain one symbol."])},
  "The password must contain one upper case letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain one upper case letter."])},
  "The password must contain one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain one upper case letter."])},
  "The height format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The height format is invalid."])},
  "The weight format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weight format is invalid."])},
  "1m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1min"])},
  "5m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5min"])},
  "10m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10min"])},
  "30m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30min"])},
  "1h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1hr"])},
  "3h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3hr"])},
  "5h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5hr"])},
  "10h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10hr"])},
  "1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1d"])},
  "3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3d"])},
  "1w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1d"])},
  "The login id must contain at least one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id must contain at least one symbol."])},
  "The login id must contain at least one number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id must contain at least one number."])},
  "The login id must contain at least one letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id must contain at least one letter."])},
  "The login id format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login id format is invalid."])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location management"])},
  "conductorAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conductor analysis"])},
  "the login format is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The login format is invalid"])},
  "The height must not be greater than 999.999.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the height must not be greater than 999.999"])},
  "The weight must not be greater than 999.999.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the weight must not be greater than 999.999"])},
  "passwordGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Half-width alphanumerical symbols only * Include at least one capital letter or symbol"])},
  "Longitude must be within 3 positive integers, and enter a positive decimal number with the first decimal place or more.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude must be within 3 positive integers, and enter a positive decimal number with the first decimal place or more."])},
  "Enter the latitude within 2 digits of a positive integer, and enter a positive decimal number with the first decimal place or more.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the latitude within 2 digits of a positive integer, and enter a positive decimal number with the first decimal place or more."])},
  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
}