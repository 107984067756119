import { httpBase } from "./http-common";

export function post(endpoint, data, config) {
  return config
    ? httpBase().post(`${endpoint}`, data, config)
    : httpBase().post(`${endpoint}`, data);
}

export function put(endpoint, data, config) {
  return config
    ? httpBase().put(`${endpoint}`, data, config)
    : httpBase().put(`${endpoint}`, data);
}

export function get(endpoint, data, config) {
  return config
    ? httpBase().get(`${endpoint}`, data, config)
    : httpBase().get(`${endpoint}`, data);
}

export function remove(endpoint, data, config) {
  return config
    ? httpBase().delete(`${endpoint}`, data, config)
    : httpBase().delete(`${endpoint}`, data);
}
