export default {
  "agriHealthManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アグリヘルスマネージャー"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン状態を保存する"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
  "rememberPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを覚えていますか？"])},
  "resetInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定するには、以下にメールアドレスを入力してください。"])},
  "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを送信"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定する"])},
  "adminManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者管理"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
  "clientManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント管理"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー追加"])},
  "addClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント追加"])},
  "addAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者追加"])},
  "addField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場追加"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
  "viewAdmins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者一覧"])},
  "viewClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント一覧"])},
  "adminName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名"])},
  "loginId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインID"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
  "remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認用パスワード"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー作成"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S.N"])},
  "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント名"])},
  "removeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザ削除"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント削除"])},
  "deleteAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者削除"])},
  "deleteWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者削除"])},
  "confirmRemoveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消去してもよろしいですか"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクション"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場"])},
  "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場"])},
  "fieldManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場管理"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当に"])},
  "youWantToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザを削除しますか？"])},
  "youWantToDeleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["を削除しますか？"])},
  "fieldList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場一覧"])},
  "adminEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者編集"])},
  "clientEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント編集"])},
  "fieldEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場編集"])},
  "workerEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者編集"])},
  "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動線分析"])},
  "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名"])},
  "clientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントID"])},
  "selectClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントを選択"])},
  "selectClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントを選択"])},
  "selectFieldId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場を入力してください。"])},
  "selectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場を選択"])},
  "selectGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別を選択"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
  "prefectureId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
  "prefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県"])},
  "selectPrefectureId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県を選択"])},
  "selectPrefecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県を選択"])},
  "temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所（市区町村郡）"])},
  "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2（町名・番地）"])},
  "address3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所3（マンション・建物名・部屋番号等）"])},
  "address1Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "address2Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所２"])},
  "address3Short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所３"])},
  "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度"])},
  "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度"])},
  "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面積(ha)"])},
  "createField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場登録"])},
  "createWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者登録"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者登録"])},
  "createClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント登録"])},
  "removeField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場削除"])},
  "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
  "Admin created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者ユーザを追加しました。"])},
  "Admin edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者ユーザの編集を完了しました。"])},
  "Password changed successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを変更しました。"])},
  "Client created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントを追加しました。"])},
  "Client edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの編集を完了しました。"])},
  "Admin successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者ユーザを削除しました。"])},
  "Client successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの削除を完了しました。"])},
  "Field created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場を追加しました。"])},
  "Field edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場の編集を完了しました。"])},
  "Field successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場の削除を完了しました。"])},
  "You can not delete your own profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自分のプロフィールを削除することができません。"])},
  "The login id must only contain letters and numbers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDは全角・半角文字数で入力してください。"])},
  "The worker  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名は全角・半角文字数で入力してください。"])},
  "The field  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名は全角・半角文字数で入力してください。"])},
  "The client  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント名は全角・半角文字数で入力してください。"])},
  "The admin  name must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名は全角・半角文字数で入力してください。"])},
  "Data not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当するデータが見つかりません。"])},
  "The prefecture title field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県を選択してください。"])},
  "The login id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDを入力してください。"])},
  "The password field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力してください。"])},
  "The user credentials were incorrect.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDまたはパスワードが正しくありません。"])},
  "Please enter email address.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください。"])},
  "This email is not registered.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメールアドレスを登録されていません。"])},
  "The field name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名を入力してください。"])},
  "The client id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントを選択してください。"])},
  "The postal code field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号を入力してください。"])},
  "The prefecture id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都道府県を入力してください。"])},
  "The address 1 field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所 （町名・番地）を入力してください。"])},
  "The address 2 field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2 （町名・番地）を入力してください。"])},
  "The latitude field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度欄を入力してください。"])},
  "The longitude field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度欄を入力してください。"])},
  "The field name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名は255以内で入力してください。"])},
  "The field name format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名は正しい形式で入力してください。"])},
  "Field already exists for client.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場名すでに使用されています。新しい圃場名を入力してください。"])},
  "The postal code must be between 7 and 8 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号は7〜８桁数で入力してください。"])},
  "The postal code must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号は数字のみで入力してください。"])},
  "The address 1 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所（市区町村郡）は255文字数以内で入力してください。"])},
  "The address 1 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所（市区町村郡）は正しい形式で入力してください。"])},
  "The address 1 must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所（市区町村郡）は最低1つ文字数で入力してください。"])},
  "The address 2 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2（町名・番地）は255文字数以内で入力してください。"])},
  "The address 2 must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2（町名・番地）は最低1つ文字数で入力してください。"])},
  "The address 2 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2（町名・番地）は正しい形式で入力してください。"])},
  "The address 3 must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所3（マンション・建物名・部屋番号等）は255文字数以内で入力してください。"])},
  "The address 3 format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所3（マンション・建物名・部屋番号等）は正しい形式で入力してください。"])},
  "The latitude format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度は正の整数2桁以内、正の少数を小数第一位以上で入力してください。"])},
  "The latitude must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度は正の整数2桁以内、正の少数を小数第一位以上で入力してください。"])},
  "The longitude format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度は正しい形式で入力してください。"])},
  "The longitude must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度は文字数のみで入力してください。"])},
  "The area format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["面積は正の整数、正の少数を小数第二位までで入力してください。"])},
  "The address 1 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所(市区町村郡)は全角・半角英数で入力してください。"])},
  "The address 2 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所2(町名・番地)は全角・半角英数で入力してください。"])},
  "The address 3 must contain alphanumeric or japanese character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所住所3(マンション・建物名・部屋番号等)は全角・半角英数で入力してください。"])},
  "The admin name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名を255文字数以内で入力してください。"])},
  "The admin name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名を入力してください。"])},
  "The login Id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDを入力してください。"])},
  "The client name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント名を入力してください。"])},
  "The email field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください。"])},
  "The password confirmation field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認用パスワードを入力してください。"])},
  "The client name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント名は255文字以内で入力してください。"])},
  "The login id must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDは255文字以内で入力してください。"])},
  "The remarks must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考は255文字以内で入力してください。"])},
  "The email must be a valid email address.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正しいメールアドレスを入力してください。"])},
  "The password confirmation and password must match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードと確認用パスワードが一致する必要があります。もう一度入力してください。"])},
  "The password must contain at least one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力する時、1つ記号を含めて入力してください。"])},
  "The password must not be greater than 32 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは32文字数以内で入力してください。"])},
  "The password must contain at least one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The password must be at least 6 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは6文字数以上で入力してください。"])},
  "The email has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメールアドレスはすでに使用されています。新しいメールアドレスを入力してください。"])},
  "The login id has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このログインIDはすでに使用されています。新しいログインIDを入力してください。"])},
  "The password must be greater than 6 and less than 32 character.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" パスワードは6〜32文字数以内で入力してください。"])},
  "The password must contain one symbol and one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください"])},
  "The admin name must only contain letters and numbers.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名は全角・半角文字数で入力してください。"])},
  "Admin not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDまたはパスワードが正しくありません。"])},
  "Client not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインIDまたはパスワードが正しくありません。"])},
  "Worker not found . Please register to login.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者のデータがありません。ログインするのに、新規登録してください。"])},
  "Password Reset Email Sent to Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定用のメールを送信しました。"])},
  "Password Reset Email Sent to User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定用のメールを送信しました。"])},
  "Password successfully reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定を完了しました。"])},
  "Password successfully reset.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定を完了しました。"])},
  "This email is not registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このメールアドレスを登録されていません。"])},
  "The client is associated to field and worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクライアントは圃場と作業者に関連付けられています。"])},
  "The client is associated to field.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクライアントは圃場に関連付けられています。"])},
  "The client is associated to worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このクライアントは作業者に関連付けられています。"])},
  "This field is associated to worker.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この圃場は作業者に関連付けられています。"])},
  "The field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場を選択してください。"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報管理"])},
  "editDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 詳細編集"])},
  "fieldId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィールドID"])},
  "workerManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者管理"])},
  "worker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者"])},
  "workerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者"])},
  "viewWorkers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View 作業者"])},
  "addWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者追加"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長(cm)"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重(kg)"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "The worker name worker is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名を入力してください。"])},
  "The field id field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場を選択してください。"])},
  "The gender field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別を選択してください。"])},
  "The height field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長を入力してください。"])},
  "The weight field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重を入力してください。"])},
  "The birthday field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日を入力してください。"])},
  "Worker successfully deleted.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者の削除を完了しました。"])},
  "The worker name must be at least 1 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名は最低1つ文字数で入力してください。"])},
  "The worker name must not be greater than 255 characters.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名は255文字数以内で入力してください。"])},
  "The worker name format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名は正しい形式で入力してください。"])},
  "This field does not belongs to client.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この圃場はクライアントに属しません。"])},
  "The weight must be a number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重は数字のみで入力してください。"])},
  "The height must be between 2 and 3 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長は2〜3桁数で入力してください。"])},
  "The weight must be between 1 and 3 digits.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重は1〜3桁数で入力してください。"])},
  "The birthday is not a valid date.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正しい生年月日を入力してください。"])},
  "The birthday must be a date before today.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日は過去の日付で入力してください。"])},
  "The image must be an image.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像はJPEG、GIF、PNG、SVG形式のファイルをアップロードしてください。"])},
  "The image must be a file of type: jpeg, png, gif, svg.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像はJPEG、GIF、PNG、SVG形式のファイルをアップロードしてください。"])},
  "The image must not be greater than 2048 kilobytes.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像ファイルのは2048KBサイズ以内でアップロードしてください。"])},
  "The image has invalid image dimensions.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じ幅と高さの画像ファイルをアップロードしてください。"])},
  "The clientId field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じ幅と高さの画像ファイルをアップロードしてください。"])},
  "The worker name field is required.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者名を入力してください。"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者一覧"])},
  "worker created successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者を追加しました。"])},
  "worker edited successfully.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者の編集を完了しました。"])},
  "FieldTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圃場気温"])},
  "HeartRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心拍数"])},
  "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気温"])},
  "BodyTemperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体温"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
  "heartRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心拍数"])},
  "currentLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在地"])},
  "workerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作業者情報 詳細"])},
  "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択"])},
  "noFileChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択されていません"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリア"])},
  "The password must contain one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The password must contain one upper case letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは最低１つ大文字を含めて入力してください。"])},
  "The password must contain one uppercase letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The height format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長は整数と小数(2桁まで)の形式で入力してください。"])},
  "The weight format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体重は整数と小数(2桁まで)の形式で入力してください。"])},
  "1m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1分"])},
  "5m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5分"])},
  "10m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10分"])},
  "30m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30分"])},
  "1h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1時間"])},
  "3h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3時間"])},
  "5h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5時間"])},
  "10h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10時間"])},
  "1d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1日"])},
  "3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3日"])},
  "1w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1週間"])},
  "The login id must contain at least one symbol.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The login id must contain at least one number.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The login id must contain at least one letter.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The login id format is invalid.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報管理"])},
  "conductorAnalysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動線分析管理"])},
  "the login format is invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["半角英数記号で入力してください。"])},
  "The height must not be greater than 999.999.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999.999以内で入力してください。"])},
  "The weight must not be greater than 999.999.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["999.999以内で入力してください。"])},
  "passwordGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※半角英数記号のみ※大文字や記号を少なくとも一文字含める"])},
  "Longitude must be within 3 positive integers, and enter a positive decimal number with the first decimal place or more.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経度は正の整数3桁以内、正の少数を小数第一位以上で入力してください。"])},
  "Enter the latitude within 2 digits of a positive integer, and enter a positive decimal number with the first decimal place or more.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緯度は正の整数2桁以内、正の少数を小数第一位以上で入力してください。"])},
  "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["わからない"])}
}