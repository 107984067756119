<template>
  <AdminTable />
</template>

<script>
import AdminTable from "../../components/table/AdminTable.vue";
export default {
  name: "AdminManagement",
  components: {
    AdminTable,
  },
};
</script>