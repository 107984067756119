<template>
  <div>
    <!-- Start Topbar Mobile -->
    <div class="topbar-mobile">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="mobile-logobar">
            <a href="index.html" class="mobile-logo"
              ><img src="/assets/logo.png" class="img-fluid" alt="logo"
            /></a>
          </div>
          <div class="mobile-togglebar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <div class="topbar-toggle-icon">
                  <a class="topbar-toggle-hamburger">
                    <i class="ri-more-fill menu-hamburger-horizontal"></i>
                    <i class="ri-more-2-fill menu-hamburger-vertical"></i>
                  </a>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="menubar">
                  <a class="menu-hamburger">
                    <i class="ri-menu-2-line menu-hamburger-collapse"></i>
                    <i class="ri-close-line menu-hamburger-close"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Topbar -->
    <div class="topbar">
      <!-- Start row -->
      <div class="row align-items-center">
        <!-- Start col -->
        <div class="col-md-12 align-self-center">
          <div class="togglebar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <div class="menubar">
                  <a class="menu-hamburger">
                    <i class="ri-menu-2-line menu-hamburger-collapse"></i>
                    <i class="ri-close-line menu-hamburger-close"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div class="infobar">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <div class="profilebar">
                  <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      href="#"
                      role="button"
                      id="profilelink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      v-if="name"
                      ><img
                        src="/assets/images/users/profile.svg"
                        class="img-fluid"
                        alt="profile"
                      /><span class="live-icon">{{ name }}</span></a
                    >
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="profilelink"
                    >
                      <a
                        class="dropdown-item text-danger"
                        href="#"
                        @click="handleLogout"
                        ><i class="ri-shut-down-line"></i>{{ $t("logout") }}</a
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- End col -->
      </div>
      <!-- End row -->
    </div>
    <!-- End Topbar -->
  </div>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import {
  logout,
  clientLogout,
  viewAdminProfile,
  viewClientProfile,
} from "../../core/api/auth-service";
import { store } from "../../core/store";

export default {
  name: "Header",
  setup() {
    const router = useRouter();
    const name = ref("");

    onMounted(() => {
      if (store.getters.role === "admin") {
        viewAdminProfile()
          .then((res) => {
            name.value = res.data.data.admin_name;
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        viewClientProfile()
          .then((res) => {
            name.value = res.data.data.client_name;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });

    const handleLogout = () => {
      if (store.getters.role === "admin") {
        logout()
          .then((res) => {
            if (res.status === 200) router.push({ name: "Login" });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        clientLogout()
          .then((res) => {
            if (res.status === 200) router.push({ name: "ClientLogin" });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    return {
      handleLogout,
      name,
    };
  },
};
</script>