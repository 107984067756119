<template>
  <div id="infobar-settings-sidebar" class="infobar-settings-sidebar">
    <div
      class="infobar-settings-sidebar-head d-flex w-100 justify-content-between"
    >
      <h4>Settings</h4>
      <a id="infobar-settings-close" class="infobar-settings-close"
        ><i class="ri-close-line menu-hamburger-close"></i
      ></a>
    </div>
    <div class="infobar-settings-sidebar-body">
      <div class="custom-mode-setting">
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">Payment Reminders</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-first" checked />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">Stock Updates</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-second" checked />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">Open for New Products</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-third" />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">Enable SMS</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-fourth" checked />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8">
            <h6 class="mb-0">Newsletter Subscription</h6>
          </div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-fifth" checked />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">Show Map</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-sixth" />
          </div>
        </div>
        <div class="row align-items-center pb-3">
          <div class="col-8"><h6 class="mb-0">e-Statement</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-seventh" checked />
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-8"><h6 class="mb-0">Monthly Report</h6></div>
          <div class="col-4">
            <input type="checkbox" class="js-switch-setting-eightth" checked />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="infobar-settings-sidebar-overlay"></div>
</template>

<script>
export default {
  name: "InfoBarRight",
};
</script>

<style>
</style>