import { createStore } from "vuex";
import { auth } from "./modules/auth";
import { toasterMessage } from "./modules/toaster";
import { modalMessage } from "./modules/modal";

export const store = createStore({
  modules: {
    authentication: auth,
    toast: toasterMessage,
    modal: modalMessage,
  },
});
