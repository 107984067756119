import config from "../../config";
import { post } from "../../api/api-service";
import constants from "../../constants";

export const auth = {
  state: {
    authenticated: !!localStorage.getItem("token"),
    user: null,
    role: localStorage.getItem("role")
      ? localStorage.getItem("role")
      : "admin",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
  },
  mutations: {
    setAuthentication(state, status) {
      state.authenticated = status;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setRole(state, role) {
      state.role = role;
      localStorage.setItem("role", role);
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, payload) {
      const response = await post(constants.adminLoginUrl, {
        loginId: payload.name,
        password: payload.password,
        clientId: config.clientId,
        clientSecret: config.clientSecret,
        grantType: config.grantType,
      });
      const { accessToken } = response.data.data;
      commit("setAuthentication", true);
      commit("setToken", accessToken);
      commit("setRole", "admin");
      return response;
    },
    async logout({ commit }) {
      const response = await post(constants.adminLogoutUrl);
      if (response.status) {
        commit("setAuthentication", false);
        commit("setToken", "");
        commit("setUser", null);
        commit("setRole", "");
      }
      return response;
    },

    async clientLogin({ commit }, payload) {
      const response = await post(constants.clientLoginUrl, {
        loginId: payload.name,
        password: payload.password,
        clientId: config.clientClientId,
        clientSecret: config.clientClientSecret,
        grantType: config.clientGrantType,
      });
      const { accessToken } = response.data.data;
      commit("setAuthentication", true);
      commit("setToken", accessToken);
      commit("setRole", "client");
      return response;
    },
    async clientLogout({ commit }) {
      const response = await post(constants.clientLogoutUrl);
      if (response.status) {
        commit("setAuthentication", false);
        commit("setToken", "");
        commit("setUser", null);
        commit("setRole", "");
      }
      return response;
    },
  },
  getters: {
    authenticated: (state) => state.authenticated,
    token: (state) => state.token,
    role: (state) => state.role,
    user: (state) => state.user,
    name: (state) => state.name,
  },
};
