<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-5">
        <div class="card-header">
          <div class="row w-100">
            <div class="col-8">
              <h5 class="main-card-title">{{ $t("fieldList") }}</h5>
            </div>
            <div class="col-4 d-flex">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('search')"
                  v-model="keyword"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    id="button-addon-group"
                    @click="search"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
              <button
                class="btn btn-outline-secondary reset-button"
                @click="clearSearch"
              >
                {{ $t("reset") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div
              v-if="loading"
              class="d-flex justify-content-center"
              style="height: 100vh"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table
              class="table foo-filtering-table"
              data-filtering="true"
              v-else
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("fieldName") }}
                    <span
                      class="sorting ml-2"
                      :class="{
                        'sort-up':
                          selectedField === 'field_name' && sortKeys.field_name,
                        'sort-down':
                          selectedField === 'field_name' &&
                          !sortKeys.field_name,
                      }"
                      @click="sortData('field_name')"
                    ></span>
                  </th>
                  <th style="min-width: 85px">
                    {{ $t("prefecture") }}
                    <span
                      class="sorting ml-2"
                      :class="{
                        'sort-up':
                          selectedField === 'prefecture_title' &&
                          sortKeys.prefecture_title,
                        'sort-down':
                          selectedField === 'prefecture_title' &&
                          !sortKeys.prefecture_title,
                      }"
                      @click="sortData('prefecture_title')"
                    ></span>
                  </th>
                  <th>{{ $t("location") }}</th>
                  <th>
                    {{ $t("Temperature") }}
                    <span
                      class="sorting ml-2"
                      :class="{
                        'sort-up':
                          selectedField === 'temperature' &&
                          sortKeys.temperature,
                        'sort-down':
                          selectedField === 'temperature' &&
                          !sortKeys.temperature,
                      }"
                      @click="sortData('temperature')"
                    ></span>
                  </th>
                  <th>
                    {{ $t("area") }}
                    <span
                      class="sorting ml-2"
                      :class="{
                        'sort-up': selectedField === 'area' && sortKeys.area,
                        'sort-down': selectedField === 'area' && !sortKeys.area,
                      }"
                      @click="sortData('area')"
                    ></span>
                  </th>
                  <th>{{ $t("remarks") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="field of fieldsList" :key="field.id">
                  <td>{{ field.field_name }}</td>
                  <td>{{ field.prefecture_name }}</td>
                  <td>
                    <a
                      :href="`http://maps.google.com/?q=${field.latitude},${field.longitude}`"
                      target="_blank"
                      >{{ field.latitude }}, {{ field.longitude }}</a
                    >
                  </td>
                  <td>{{ field.temperature }}</td>
                  <td>{{ field.area }}</td>
                  <td>{{ field.remarks }}</td>
                  <td>
                    <div class="btn-group btn-group-sm" style="float: none">
                      <router-link
                        v-if="role === 'admin'"
                        :to="{
                          name: 'FieldAdminFormEdit',
                          params: { id: field.id },
                        }"
                        class="
                          btn btn-sm btn-outline-primary
                          rounded
                          d-flex
                          align-items-center
                        "
                        style="float: none; margin: 5px"
                        ><span class="ti-pencil"></span
                      ></router-link>
                      <router-link
                        v-if="role === 'client'"
                        :to="{
                          name: 'FieldClientFormEdit',
                          params: { id: field.id },
                        }"
                        class="
                          btn btn-sm btn-outline-primary
                          rounded
                          d-flex
                          align-items-center
                        "
                        style="float: none; margin: 5px"
                        ><span class="ti-pencil"></span
                      ></router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-danger rounded"
                        style="float: none; margin: 5px"
                        @click="removeField(field.id, field.field_name)"
                      >
                        <span class="ti-trash"></span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div class="footable-paging">
          <div>
            <div
              class="footable-pagination-wrapper d-flex justify-content-center"
            >
              <ul class="pagination">
                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-if="pagination.previousPageUrl"
                >
                  <a class="footable-page-link btn" @click="getPrevPageData()"
                    >‹</a
                  >
                </li>

                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-else
                >
                  <a class="footable-page-link text-secondary btn">‹</a>
                </li>
                <li
                  class="footable-page visible"
                  :class="{ active: pagination.activePage == page }"
                  v-for="(page, index) of totalPages"
                  :key="index"
                >
                  <a
                    class="footable-page-link btn"
                    @click="getPageData(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  class="footable-page-nav ml-4"
                  v-if="pagination.nextPageUrl"
                >
                  <a class="footable-page-link btn" @click="getNextPageData()"
                    >›</a
                  >
                </li>
                <li class="footable-page-nav ml-4" v-else>
                  <a class="footable-page-link btn text-secondary">›</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect, computed } from "vue";
import { get } from "../../core/api/api-service";
import { store } from "../../core/store";

export default {
  name: "FieldTable",
  setup() {
    const fieldsList = ref([]);
    const role = store.getters.role;
    const pagination = ref({
      totalCount: null,
      perPage: "20",
      currentPage: 1,
      nextPageUrl: "",
      activePage: 1,
      previousPageUrl: "",
    });
    const keyword = ref("");
    const sortKeys = ref({
      field_name: false,
      prefecture_title: false,
      area: false,
      temperature: false,
    });
    const selectedField = ref("");

    const loading = ref(true);

    const prefecturesList = ref([
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ]);

    const addSerialNo = () => {
      for (let i = 0; i < fieldsList.value.length; i++) {
        fieldsList.value["sn"] = null;
        fieldsList.value[i].sn =
          i + 1 + pagination.value.perPage * (pagination.value.activePage - 1);
      }
    };

    onMounted(() => {
      getFieldData();
    });

    const convertPrefecture = () => {
      fieldsList.value.forEach((item) => {
        item.prefecture_name =
          prefecturesList.value[Number(item.prefecture_name) - 1];
      });
    };

    const getFieldData = () => {
      loading.value = true;
      if (store.getters.role === "admin") {
        get(`/api/v1/admin/fields?perPage=${pagination.value.perPage}`).then(
          (res) => {
            fieldsList.value = res.data.data;
            convertPrefecture();
            pagination.value.totalCount = res.data.paginationData.totalCount;
            pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
            pagination.value.previousPageUrl =
              res.data.paginationData.previousPageUrl;
            addSerialNo();
            selectedField.value = "";
            loading.value = false;
          }
        );
      }
      if (store.getters.role === "client") {
        get(`/api/v1/client/fields?perPage=${pagination.value.perPage}`).then(
          (res) => {
            fieldsList.value = res.data.data;
            convertPrefecture();
            pagination.value.totalCount = res.data.paginationData.totalCount;
            pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
            pagination.value.previousPageUrl =
              res.data.paginationData.previousPageUrl;
            addSerialNo();
            selectedField.value = "";
            loading.value = false;
          }
        );
      }
    };

    const clearSearch = () => {
      keyword.value = "";
      getFieldData();
    };

    const getPrevPageData = () => {
      if (pagination.value.previousPageUrl) {
        get(
          `${pagination.value.previousPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "field_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.activePage--;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const getPageData = (page) => {
      pagination.value.activePage = page;
      if (store.getters.role === "admin") {
        get(
          `/api/v1/admin/fields?page=${pagination.value.activePage}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "field_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          pagination.value.currentPage = res.data.paginationData.currentPage;
          addSerialNo();
          loading.value = false;
        });
      }
      if (store.getters.role === "client") {
        get(
          `/api/v1/client/fields?page=${pagination.value.activePage}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "field_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          pagination.value.currentPage = res.data.paginationData.currentPage;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const getNextPageData = () => {
      if (pagination.value.nextPageUrl) {
        get(
          `${pagination.value.nextPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${
            selectedField?.value === "field_name"
              ? "id"
              : selectedField?.value
              ? selectedField.value
              : ""
          }&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.activePage++;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const totalPages = computed(() => {
      return Math.ceil(pagination.value.totalCount / pagination.value.perPage);
    });

    watchEffect(() => {
      store.getters.modalMessage.success;
      if (store.getters.role === "admin") {
        if (store.getters.modalMessage.success === true) {
          get(`/api/v1/admin/fields?perPage=${pagination.value.perPage}`).then(
            (res) => {
              fieldsList.value = res.data.data;
              convertPrefecture();
              pagination.value.currentPage = 1;
              pagination.value.activePage = 1;
              addSerialNo();
              loading.value = false;
            }
          );
        }
      }
      if (store.getters.role === "client") {
        if (store.getters.modalMessage.success === true) {
          get(`/api/v1/client/fields?perPage=${pagination.value.perPage}`).then(
            (res) => {
              fieldsList.value = res.data.data;
              convertPrefecture();
              pagination.value.currentPage = 1;
              pagination.value.activePage = 1;
              addSerialNo();
              loading.value = false;
            }
          );
        }
      }
    });

    const removeField = (fieldId, fieldName) => {
      store.dispatch("updateModalMessage", {
        userName: fieldName,
        userId: fieldId,
        modalInactive: false,
        page: pagination.value.perPage,
      });
    };

    const search = () => {
      loading.value = true;
      if (store.getters.role === "admin") {
        get(
          `/api/v1/admin/fields?perPage=${pagination.value.perPage}&keyword=${keyword.value}`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.totalCount = res.data.paginationData.totalCount;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          addSerialNo();
          selectedField.value = "";
          loading.value = false;
        });
      }
      if (store.getters.role === "client") {
        get(
          `/api/v1/client/fields?perPage=${pagination.value.perPage}&keyword=${keyword.value}`
        ).then((res) => {
          fieldsList.value = res.data.data;
          convertPrefecture();
          pagination.value.totalCount = res.data.paginationData.totalCount;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          addSerialNo();
          selectedField.value = "";
          loading.value = false;
        });
      }
    };

    const sortData = (sortKey) => {
      selectedField.value = sortKey;
      loading.value = true;
      for (let key in sortKeys.value) {
        if (key === sortKey) {
          sortKeys.value[key] = !sortKeys.value[key];
        } else {
          sortKeys.value[key] = false;
        }
      }
      let fieldUrl =
        store.getters.role === "admin"
          ? `/api/v1/admin/fields?page=${pagination.value.currentPage}&perPage=${pagination.value.perPage}`
          : `/api/v1/client/fields?page=${pagination.value.currentPage}&perPage=${pagination.value.perPage}`;
      if (sortKeys.value.field_name) {
        fieldUrl += `&column=id&asc=${sortKeys.value.field_name}`;
      }
      if (sortKeys.value.prefecture_title) {
        fieldUrl += `&column=prefecture_title&asc=${sortKeys.value.prefecture_title}`;
      }
      if (sortKeys.value.area) {
        fieldUrl += `&column=area&asc=${sortKeys.value.area}`;
      }
      if (sortKeys.value.temperature) {
        fieldUrl += `&column=temperature&asc=${sortKeys.value.temperature}`;
      }
      if (
        !sortKeys.value.field_name &&
        !sortKeys.value.prefecture_title &&
        !sortKeys.value.temperature &&
        !sortKeys.value.area
      ) {
        fieldUrl += `&column=${sortKey === "field_name" ? "id" : sortKey}`;
      }
      get(fieldUrl).then((res) => {
        fieldsList.value = res.data.data;
        convertPrefecture();
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        loading.value = false;
      });
    };

    return {
      fieldsList,
      removeField,
      role,
      pagination,
      totalPages,
      keyword,
      getPageData,
      getPrevPageData,
      getNextPageData,
      addSerialNo,
      loading,
      search,
      sortKeys,
      selectedField,
      sortData,
      clearSearch,
      prefecturesList,
      convertPrefecture,
    };
  },
};
</script>

<style scoped>
@import "../../assets/custom/custom-table.css";
</style>

<style>
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #e5e5e5;
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  -webkit-border-radius: 1ex;
}
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
</style>
