<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-30">
        <div class="card-body">
          <h5 class="main-card-title mb-4">
            {{ pageName }}
          </h5>
          <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <form @submit.prevent="registerField" v-else>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="clientId" class="required">{{
                  $t("client")
                }}</label>
                <span v-if="role === 'admin'">
                  <select
                    class="select2-single form-control"
                    name="clientid"
                    v-model="field.clientId"
                  >
                    <option value="" selected disabled>
                      {{ $t("selectClient") }}
                    </option>
                    <option
                      v-for="client of clientsList"
                      :key="client"
                      :value="client.id"
                    >
                      {{ client.name }}
                    </option>
                  </select>
                </span>
                <span v-if="role === 'client'">
                  <select class="select2-single form-control" name="clientid">
                    <option>
                      {{ clientName }}
                    </option>
                  </select>
                </span>
                <span class="text-danger" v-if="errors.clientId">
                  {{ errors.clientId }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="fieldName" class="required">{{
                  $t("fieldName")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="fieldName"
                  :placeholder="$t('fieldName')"
                  v-model="field.fieldName"
                  ref="focusInput"
                />
                <span class="text-danger" v-if="errors.fieldName">
                  {{ errors.fieldName }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="postalCode" class="required">{{
                  $t("postalCode")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="postalCode"
                  :placeholder="$t('postalCode')"
                  v-model="field.postalCode"
                />
                <span class="text-danger" v-if="errors.postalCode">
                  {{ errors.postalCode }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="prefectureTitle" class="required">{{
                  $t("prefecture")
                }}</label>
                <select
                  class="select2-single form-control"
                  name="prefectureName"
                  v-model="field.prefectureTitle"
                >
                  <option value="" selected disabled>
                    {{ $t("selectPrefecture") }}
                  </option>
                  <option
                    v-for="(prefecture, index) in prefecturesList"
                    :key="index"
                    :value="index + 1"
                  >
                    {{ prefecture }}
                  </option>
                </select>
                <span class="text-danger" v-if="errors.prefectureTitle">
                  {{ errors.prefectureTitle }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="address1" class="required">{{
                  $t("address1")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="address1"
                  :placeholder="$t('address1')"
                  v-model="field.address_1"
                />
                <span class="text-danger" v-if="errors.address_1">
                  {{ errors.address_1 }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="address2" class="required">{{
                  $t("address2")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="address2"
                  :placeholder="$t('address2')"
                  v-model="field.address_2"
                />
                <span class="text-danger" v-if="errors.address_2">
                  {{ errors.address_2 }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="address3">{{ $t("address3") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="address3"
                  :placeholder="$t('address3')"
                  v-model="field.address_3"
                />
                <span class="text-danger" v-if="errors.address_3">
                  {{ errors.address_3 }}</span
                >
              </div>
              <div class="form-group col-md-6"></div>
              <div class="form-group col-md-6">
                <label for="latitude" class="required">{{
                  $t("latitude")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="latitude"
                  :placeholder="$t('latitude')"
                  v-model="field.latitude"
                />
                <span class="text-danger" v-if="errors.latitude">
                  {{ errors.latitude }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="longitude" class="required">{{
                  $t("longitude")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="longitude"
                  :placeholder="$t('longitude')"
                  v-model="field.longitude"
                />
                <span class="text-danger" v-if="errors.longitude">
                  {{ errors.longitude }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="area">{{ $t("area") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="area"
                  :placeholder="$t('area')"
                  v-model="field.area"
                />
                <span class="text-danger" v-if="errors.area">
                  {{ errors.area }}</span
                >
              </div>
              <div class="form-group col-md-6"></div>

              <div class="form-group col-md-6">
                <label for="remarks">{{ $t("remarks") }}</label>
                <textarea
                  rows="4"
                  type="text"
                  class="form-control"
                  id="remarks"
                  :placeholder="$t('remarks')"
                  v-model="field.remarks"
                />
                <span class="text-danger" v-if="errors.remarks">
                  {{ errors.remarks }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button type="submit" class="btn btn-outline-primary">
                  <span
                    v-if="
                      formType === 'FieldAdminFormCreate' ||
                      formType === 'FieldClientFormCreate'
                    "
                  >
                    {{ $t("save") }}
                  </span>
                  <span v-else>
                    {{ $t("update") }}
                  </span>
                </button>
                <router-link
                  to="/admin/field-management"
                  class="btn btn-outline-secondary ml-2"
                  v-if="role === 'admin'"
                >
                  {{ $t("cancel") }}
                </router-link>
                <router-link
                  to="/client/field-management"
                  class="btn btn-outline-secondary ml-2"
                  v-if="role === 'client'"
                >
                  {{ $t("cancel") }}
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End col -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { watchEffect, onMounted } from "@vue/runtime-core";
import constants from "../../core/constants";
import { toastMessage } from "../../core/utils";
import { post, put, get } from "../../core/api/api-service";
import {
  getFieldById,
  getFieldByIdClient,
  viewClientProfile,
} from "../../core/api/auth-service";
import { store } from "../../core/store";
import i18n from "@/i18n";

export default {
  name: "FieldForm",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const field = ref({
      fieldName: "",
      clientId: "",
      postalCode: "",
      prefectureTitle: "",
      address_1: "",
      address_2: "",
      address_3: "",
      latitude: "",
      longitude: "",
      area: "",
      remarks: "",
    });
    const errors = ref([]);
    const successMessage = ref("");
    const formType = ref("");
    const clientsList = ref([]);
    const totalClients = ref("");
    const role = store.getters.role;
    const focusInput = ref(null);
    const clientName = ref("");

    const fieldId = route.params.id;

    const loading = ref(false);

    const pageName = ref(route.meta.breadcrumb[2]?.title);
    const prefecturesList = ref([
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ]);

    const registerField = () => {
      errors.value = [];
      successMessage.value = "";
      if (!fieldId && store.getters.role === "admin") {
        post(constants.fieldAdminUrl, field.value)
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "AdminFieldManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Field created successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (!fieldId && store.getters.role === "client") {
        post(constants.fieldClientUrl, field.value)
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "ClientFieldManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Field created successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (fieldId && store.getters.role === "admin") {
        put(`${constants.fieldAdminUrl}/${fieldId}`, field.value)
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "AdminFieldManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Field edited successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (fieldId && store.getters.role === "client") {
        put(`${constants.fieldClientUrl}/${fieldId}`, field.value)
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "ClientFieldManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Field edited successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
    };

    onMounted(() => {
      focusInput.value.focus();
      if (fieldId && role === "admin") {
        loading.value = true;
        getFieldById(fieldId)
          .then((res) => {
            if (res.data.data.id == fieldId) {
              field.value.fieldName = res.data.data.field_name;
              field.value.clientId = res.data.data.client_id;
              field.value.postalCode = res.data.data.postal_code;
              field.value.prefectureTitle = res.data.data.prefecture_name;
              field.value.address_1 = res.data.data.address_1;
              field.value.address_2 = res.data.data.address_2;
              field.value.address_3 = res.data.data.address_3;
              field.value.latitude = res.data.data.latitude;
              field.value.longitude = res.data.data.longitude;
              field.value.area = res.data.data.area;
              field.value.remarks = res.data.data.remarks;
              loading.value = false;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      if (role === "client") {
        loading.value = true;
        viewClientProfile()
          .then((res) => {
            clientName.value = res.data.data.client_name;
            loading.value = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (fieldId && role === "client") {
        loading.value = true;
        getFieldByIdClient(fieldId)
          .then((res) => {
            if (res.data.data.id == fieldId) {
              field.value.fieldName = res.data.data.field_name;
              field.value.clientId = res.data.data.client_id;
              field.value.postalCode = res.data.data.postal_code;
              field.value.prefectureTitle = res.data.data.prefecture_name;
              field.value.address_1 = res.data.data.address_1;
              field.value.address_2 = res.data.data.address_2;
              field.value.address_3 = res.data.data.address_3;
              field.value.latitude = res.data.data.latitude;
              field.value.longitude = res.data.data.longitude;
              field.value.area = res.data.data.area;
              field.value.remarks = res.data.data.remarks;
              clientName.value = res.data.data.client.client_name;
              loading.value = false;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });

    watchEffect(() => {
      var postal_code = require("japan-postal-code");
      postal_code.get(field.value.postalCode, function (address) {
        field.value.prefectureTitle =
          prefecturesList.value.indexOf(address.prefecture) + 1;
        field.value.address_1 = address.city;
        field.value.address_2 = address.area;
      });
      if (store.getters.role === "admin") {
        get(`${constants.adminGetAllClients}`).then((res) => {
          clientsList.value = res.data.data;
        });
      }

      if (store.getters.role === "client") {
        get(`${constants.viewClientProfileUrl}`).then((res) => {
          field.value.clientId = res.data.data.client_name;
        });
      }

      formType.value = router.currentRoute.value.name;
    });
    return {
      field,
      errors,
      fieldId,
      successMessage,
      registerField,
      formType,
      clientsList,
      totalClients,
      role,
      focusInput,
      loading,
      clientName,
      pageName,
      prefecturesList,
    };
  },
};
</script>