import { store } from "../store/index";
import { get, post, put } from "./api-service";
import constants from "../constants";

export const login = (formValues) => {
  return new Promise((resolve, reject) => {
    store
      .dispatch("login", formValues)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch("logout")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const clientLogin = (formValues) => {
  return new Promise((resolve, reject) => {
    store
      .dispatch("clientLogin", formValues)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const clientLogout = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch("clientLogout")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyResetPasswordToken = (userId, token) => {
  return new Promise((resolve, reject) => {
    post(constants.adminResetPasswordTokenValidateUrl, { userId, token })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyClientResetPasswordToken = (userId, token) => {
  return new Promise((resolve, reject) => {
    post(constants.clientResetPasswordTokenValidateUrl, { userId, token })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPassword = (passwordFormWithToken) => {
  return new Promise((resolve, reject) => {
    post(constants.adminResetPasswordUrl, passwordFormWithToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetClientPassword = (passwordFormWithToken) => {
  return new Promise((resolve, reject) => {
    post(constants.clientResetPasswordUrl, passwordFormWithToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPassword = (email) => {
  return new Promise((resolve, reject) => {
    post(constants.adminForgetPasswordUrl, {
      email,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const clientForgetPassword = (email) => {
  return new Promise((resolve, reject) => {
    post(constants.clientForgetPasswordUrl, {
      email,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const viewAdminProfile = () => {
  return new Promise((resolve, reject) => {
    get(`${constants.viewAdminProfileUrl}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const viewClientProfile = () => {
  return new Promise((resolve, reject) => {
    get(`${constants.viewClientProfileUrl}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createAdmin = (user) => {
  return new Promise((resolve, reject) => {
    post(constants.adminsUrl, user)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateAdmin = (adminId, user) => {
  return new Promise((resolve, reject) => {
    put(`${constants.adminsUrl}/${adminId}`, user)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAdminById = (adminId) => {
  return new Promise((resolve, reject) => {
    get(`${constants.adminsUrl}/${adminId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFieldById = (fieldId) => {
  return new Promise((resolve, reject) => {
    get(`${constants.fieldAdminUrl}/${fieldId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFieldByIdClient = (fieldId) => {
  return new Promise((resolve, reject) => {
    get(`${constants.fieldClientUrl}/${fieldId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeAdminPassword = (adminId, passwordData) => {
  return new Promise((resolve, reject) => {
    post(`${constants.adminUrl}/${adminId}/change-password`, passwordData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
