<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-30">
        <div class="card-body">
          <h5 class="main-card-title mb-4">
            {{ pageName }}
          </h5>
          <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <form @submit.prevent="registerUser" v-else>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="clientName" class="required">{{
                  $t("clientName")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="clientName"
                  :placeholder="$t('clientName')"
                  v-model="user.clientName"
                  ref="focusInput"
                />
                <span class="text-danger" v-if="errors.clientName">
                  {{ errors.clientName }}</span
                >
              </div>
              <div class="form-group col-md-6">
                <label for="loginId" class="required">{{
                  $t("loginId")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="loginId"
                  :placeholder="$t('loginId')"
                  v-model="user.loginId"
                />
                <span class="text-danger" v-if="errors.loginId">
                  {{ errors.loginId }}</span
                >
              </div>

              <div class="form-group col-md-6" v-if="!clientId">
                <label for="adminPassword" class="required">{{
                  $t("password")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="adminPassword"
                  :placeholder="$t('password')"
                  v-model="user.password"
                />
                <small class="mt-2">{{ $t("passwordGuide") }}</small
                ><br />
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password }}</span
                >
              </div>

              <div class="form-group col-md-6" v-if="!clientId">
                <label for="adminConfirmPassword" class="required">{{
                  $t("confirmPassword")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="adminConfirmPassword"
                  :placeholder="$t('confirmPassword')"
                  v-model="user.passwordConfirmation"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.passwordConfirmation">
                  {{ errors.passwordConfirmation }}</span
                >
                <span class="text-danger" v-if="errors.passwordMismatch">
                  {{ errors.passwordMismatch }}</span
                >
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4" class="required">{{
                  $t("email")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  :placeholder="$t('email')"
                  v-model="user.email"
                />
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email }}</span
                >
              </div>
              <div class="form-group col-md-6"></div>

              <div class="form-group col-md-6">
                <label for="remarks">{{ $t("remarks") }}</label>
                <textarea
                  rows="4"
                  type="text"
                  class="form-control"
                  id="remarks"
                  :placeholder="$t('remarks')"
                  v-model="user.remarks"
                />
                <span class="text-danger" v-if="errors.remarks">
                  {{ errors.remarks }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button type="submit" class="btn btn-outline-primary">
                  <span v-if="formType === 'ClientFormCreate'">
                    {{ $t("save") }}
                  </span>
                  <span v-else>
                    {{ $t("update") }}
                  </span>
                </button>
                <router-link
                  to="/admin/client-management"
                  class="btn btn-outline-secondary ml-2"
                >
                  {{ $t("cancel") }}
                </router-link>
              </div>
            </div>
          </form>
          <form @submit.prevent="changePassword" v-if="clientId" class="mt-4">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="adminPassword">{{ $t("password") }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="adminPassword"
                  :placeholder="$t('password')"
                  v-model="user.password"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password }}</span
                >
              </div>

              <div class="form-group col-md-6">
                <label for="adminConfirmPassword">{{
                  $t("confirmPassword")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="adminConfirmPassword"
                  :placeholder="$t('confirmPassword')"
                  v-model="user.passwordConfirmation"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.passwordConfirmation">
                  {{ errors.passwordConfirmation }}</span
                >
                <span class="text-danger" v-if="errors.passwordMismatch">
                  {{ errors.passwordMismatch }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button type="submit" class="btn btn-outline-primary">
                  <span> {{ $t("updatePassword") }} </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End col -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { toastMessage } from "../../core/utils";
import constants from "../../core/constants";
import { get, post, put } from "../../core/api/api-service";
import i18n from "@/i18n";

export default {
  name: "ClientForm",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = ref({
      loginId: "",
      clientName: "",
      password: "",
      passwordConfirmation: "",
      email: "",
      remarks: "",
    });
    const errors = ref([]);
    const successMessage = ref("");
    const formType = ref("");
    const focusInput = ref(null);
    const loading = ref(false);

    const pageName = ref(route.meta.breadcrumb[2]?.title);

    const clientId = route.params.id;

    const registerUser = () => {
      errors.value = [];
      successMessage.value = "";
      if (!clientId) {
        post(constants.clientsUrl, user.value)
          .then((res) => {
            if (res.status === 200) router.push({ name: "ClientManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Client created successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (clientId) {
        put(`${constants.clientsUrl}/${clientId}`, user.value)
          .then(() => {
            router.push({ name: "ClientManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Client edited successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
    };

    const changePassword = () => {
      errors.value = [];
      successMessage.value = "";
      if (clientId) {
        post(`${constants.clientUrl}/${clientId}/change-password`, {
          password: user.value.password,
          passwordConfirmation: user.value.passwordConfirmation,
        })
          .then((res) => {
            successMessage.value = res.data.data.message;
            router.push({ name: "ClientManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("Password changed successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
    };

    onMounted(() => {
      focusInput.value.focus();
      formType.value = router.currentRoute.value.name;
      if (clientId) {
        loading.value = true;
        get(`${constants.clientsUrl}/${clientId}`)
          .then((res) => {
            if (res.data.data.id == clientId) {
              user.value.clientName = res.data.data.client_name;
              user.value.loginId = res.data.data.login_id;
              user.value.email = res.data.data.email;
              user.value.remarks = res.data.data.remarks;
              loading.value = false;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
    return {
      user,
      errors,
      clientId,
      successMessage,
      registerUser,
      changePassword,
      formType,
      focusInput,
      loading,
      pageName,
    };
  },
};
</script>