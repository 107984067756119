const config = {
  baseAPIUrl: process.env.VUE_APP_BASE_API_URL || "",
  clientId: process.env.VUE_APP_CLIENT_ID || "",
  clientSecret: process.env.VUE_APP_CLIENT_SECRET || "",
  grantType: process.env.VUE_APP_GRANT_TYPE || "",
  //
  clientClientId: process.env.VUE_APP_CLIENT_CLIENT_ID || "",
  clientClientSecret: process.env.VUE_APP_CLIENT_CLIENT_SECRET || "",
  clientGrantType: process.env.VUE_APP_CLIENT_GRANT_TYPE || "",
  pusherId: process.env.VUE_APP_PUSHER_ID || "",
  pusherCluster: process.env.VUE_APP_PUSHER_CLUSTER || "",
};

export default config;
