<template>
  <!-- Start Footerbar -->
  <div class="footerbar">
    <footer class="footer">
      <p class="mb-0">© 2022 AHM - All Rights Reserved.</p>
    </footer>
  </div>
  <!-- End Footerbar -->
</template>

<script>
export default {
  name: "Footer",
};
</script>