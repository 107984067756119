export default {
  // Admin URLs
  adminUrl: "/api/v1/admin",
  adminsUrl: "/api/v1/admins",
  adminLoginUrl: `/api/v1/admin/login`,
  adminLogoutUrl: `/api/v1/admin/logout`,
  viewAdminProfileUrl: "/api/v1/admin/view-profile",
  adminForgetPasswordUrl: "/api/v1/admin/forget-password",
  adminResetPasswordTokenValidateUrl:
    "/api/v1/admin/reset-password/validate-token",
  adminResetPasswordUrl: "/api/v1/admin/reset-password",

  // Client URLs
  clientUrl: "/api/v1/client",
  adminGetAllClients: "/api/v1/admin/get-clients",
  getPrefectures: "/api/v1/admin/get-prefectures",
  getClientPrefectures: "/api/v1/client/get-prefectures",
  clientLoginUrl: `/api/v1/client/login`,
  clientLogoutUrl: `/api/v1/client/logout`,
  viewClientProfileUrl: "/api/v1/client/view-profile",
  clientForgetPasswordUrl: "/api/v1/client/forget-password",
  clientResetPasswordTokenValidateUrl:
    "/api/v1/client/reset-password/validate-token",
  clientResetPasswordUrl: "/api/v1/client/reset-password",
  clientsUrl: "/api/v1/clients",

  // Field URLs
  fieldAdminUrl: "/api/v1/admin/fields",
  fieldClientUrl: "/api/v1/client/fields",

  // worker URLs
  workerAdminUrl: "/api/v1/admin/workers",
  workerClientUrl: "/api/v1/client/workers",

  workerHeartBeatAdminUrl: "/api/v1/admin/record",
  workerHeartBeatClientUrl: "/api/v1/client/record",

  // Location fields URL
  adminFieldLocationURL: "/api/v1/admin/worker/field",
  clientFieldLocationURL: "/api/v1/client/worker/field",

  // Conductor Analysis URL
  adminAnalysisURL: "/api/v1/admin/conductor-analysis",
  clientAnalysisURL: "/api/v1/client/conductor-analysis",
};
