<template>
  <!-- Start Breadcrumbbar -->
  <div class="breadcrumbbar">
    <div class="row align-items-center">
      <div class="col-md-8 col-lg-8">
        <div class="media">
          <span class="breadcrumb-icon"><i :class="breadcrumbIcon"></i></span>
          <div class="media-body">
            <h4 class="page-title">
              {{ breadcrumbs[breadcrumbs.length - 1]?.title }}
            </h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <template
                  v-for="(breadcrumb, index) of breadcrumbs"
                  :key="index"
                >
                  <li class="breadcrumb-item">
                    <router-link
                      :to="{ name: breadcrumb.pathName }"
                      :class="
                        index === breadcrumbs.length - 1
                          ? 'breadcrumb-item active'
                          : 'breadcrumb-item'
                      "
                    >
                      {{ breadcrumb?.title }}
                    </router-link>
                  </li>
                </template>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4">
        <div class="widgetbar">
          <router-link
            :to="{ name: 'AdminFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'AdminManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addAdmin") }}</span>
          </router-link>
          <router-link
            :to="{ name: 'ClientFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'ClientManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addClient") }}</span>
          </router-link>
          <router-link
            :to="{ name: 'FieldAdminFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'AdminFieldManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addField") }}</span>
          </router-link>
          <router-link
            :to="{ name: 'FieldClientFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'ClientFieldManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addField") }}</span>
          </router-link>
          <router-link
            :to="{ name: 'WorkerAdminFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'AdminWorkerManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addWorker") }}</span>
          </router-link>
          <router-link
            :to="{ name: 'WorkerClientFormCreate' }"
            class="btn btn-outline-primary"
            v-if="currentRoute === 'ClientWorkerManagement'"
          >
            <i class="ri-add-line align-middle mr-2"></i>
            <span>{{ $t("addWorker") }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- End Breadcrumbbar -->
</template>

<script>
import { ref, onMounted, watchEffect } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "Breadcrumb",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const currentRoute = ref("");
    const mgmtType = ref("");
    const breadcrumbs = ref([]);
    const breadcrumbIcon = ref("");
    const displayAddBtn = ref(true);
    const goToCreatePage = () => {
      let pageInfo = breadcrumbs.value[breadcrumbs.value.length - 1];
      if (pageInfo.pathName === "AdminManagement") {
        router.push({ name: "AdminFormCreate" });
      } else if (pageInfo.pathName === "ClientManagement") {
        router.push({ name: "ClientFormCreate" });
      } else if (pageInfo.pathName === "AdminFieldManagement") {
        router.push({ name: "FieldAdminFormCreate" });
      } else if (pageInfo.pathName === "ClientFieldManagement") {
        router.push({ name: "FieldClientFormCreate" });
      } else if (pageInfo.pathName === "AdminWorkerManagement") {
        router.push({ name: "WorkerAdminFormCreate" });
      } else if (pageInfo.pathName === "ClientWorkerManagement") {
        router.push({ name: "WorkerClientFormCreate" });
      }
    };

    onMounted(() => {
      breadcrumbIcon.value = route.meta.breadcrumbIcon;
      let pageInfo = breadcrumbs.value[breadcrumbs.value.length - 1];

      if (pageInfo.pathName === "AdminManagement") {
        mgmtType.value = "admin";
      } else if (pageInfo.pathName === "ClientManagement") {
        mgmtType.value = "client";
      } else if (
        pageInfo.pathName === "ClientFieldManagement" ||
        pageInfo.pathName === "AdminFieldManagement"
      ) {
        mgmtType.value = "field";
      } else if (
        pageInfo.pathName === "AdminWorkerManagement" ||
        pageInfo.pathName === "ClientWorkerManagement"
      ) {
        mgmtType.value = "worker";
      }
    });

    watchEffect(() => {
      breadcrumbs.value = route.meta.breadcrumb || [];
      breadcrumbIcon.value = route.meta.breadcrumbIcon;
      displayAddBtn.value = !!route.meta.adminAddBtn;
      currentRoute.value = router.currentRoute.value.name;
    });

    return {
      displayAddBtn,
      breadcrumbs,
      goToCreatePage,
      mgmtType,
      breadcrumbIcon,
      currentRoute,
    };
  },
};
</script>