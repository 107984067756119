<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-30">
        <div class="card-body">
          <h5 class="main-card-title mb-4">
            {{ pageName }}
          </h5>
          <div v-if="loading" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <form @submit.prevent="registerWorker" v-else>
            <div class="form-row">
              <!-- worker name -->
              <div class="form-group col-md-6">
                <label for="workerName" class="required">{{
                  $t("workerName")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="workerName"
                  :placeholder="$t('workerName')"
                  v-model="worker.workerName"
                  ref="focusInput"
                />
                <span class="text-danger" v-if="errors.workerName">
                  {{ errors.workerName }}</span
                >
              </div>

              <!-- login id -->
              <div class="form-group col-md-6">
                <label for="loginId" class="required">{{
                  $t("loginId")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="loginId"
                  :placeholder="$t('loginId')"
                  v-model="worker.loginId"
                />
                <span class="text-danger" v-if="errors.loginId">
                  {{ errors.loginId }}</span
                >
              </div>

              <!-- password -->
              <div class="form-group col-md-6" v-if="!workerId">
                <label for="password" class="required">{{
                  $t("password")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  :placeholder="$t('password')"
                  v-model="worker.password"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password }}</span
                >
              </div>

              <!-- password confirmation -->
              <div class="form-group col-md-6" v-if="!workerId">
                <label for="passwordConfirmation" class="required">{{
                  $t("confirmPassword")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="passwordConfirmation"
                  :placeholder="$t('confirmPassword')"
                  v-model="worker.passwordConfirmation"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.passwordConfirmation">
                  {{ errors.passwordConfirmation }}</span
                >
              </div>

              <!-- client dropdown -->
              <div class="form-group col-md-6" v-if="role === 'admin'">
                <label for="clientId" class="required">{{
                  $t("client")
                }}</label>
                <span v-if="role === 'admin'">
                  <select
                    class="select2-single form-control"
                    name="clientid"
                    v-model="worker.clientId"
                    @change="getClientsField"
                  >
                    <option value="" selected disabled>
                      {{ $t("selectClient") }}
                    </option>
                    <option
                      v-for="client of clientsList"
                      :value="client.id"
                      :key="client"
                    >
                      {{ client.name }}
                    </option>
                  </select>
                </span>
                <span v-if="role === 'client'">
                  <select class="select2-single form-control" name="clientid">
                    <option>
                      {{ worker.clientId }}
                    </option>
                  </select>
                </span>
                <span class="text-danger" v-if="errors.clientId">
                  {{ errors.clientId }}</span
                >
              </div>

              <!-- field dropdown -->
              <div class="form-group col-md-6">
                <label for="fieldId" class="required">{{ $t("field") }}</label>
                <Select2
                  :options="fieldOptions"
                  :settings="{ multiple: true }"
                  v-model="worker.fieldId"
                  :placeholder="$t('selectField')"
                />
                <!-- @select="selectField($event)" -->
                <span class="text-danger" v-if="errors.fieldId">
                  {{ errors.fieldId }}</span
                >
              </div>

              <div class="form-group col-md-6" v-if="role === 'client'"></div>

              <!-- email -->
              <div class="form-group col-md-6">
                <label for="email" class="required">{{ $t("email") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  :placeholder="$t('email')"
                  v-model="worker.email"
                />
                <span class="text-danger" v-if="errors.email">
                  {{ errors.email }}</span
                >
              </div>
              <div class="form-group col-md-6"></div>

              <!-- gender -->
              <div class="form-group col-md-6">
                <label for="gender" class="required">{{ $t("gender") }}</label>
                <select
                  class="form-control"
                  id="gender"
                  v-model="worker.gender"
                >
                  <option value="" disabled>{{ $t("selectGender") }}</option>
                  <option value="1">{{ $t("male") }}</option>
                  <option value="2">{{ $t("female") }}</option>
                </select>
                <span class="text-danger" v-if="errors.gender">
                  {{ errors.gender }}</span
                >
              </div>
              <div class="form-group col-md-6"></div>

              <!-- height -->
              <div class="form-group col-md-6">
                <label for="height" class="required">{{ $t("height") }}</label>
                <input
                  type="number"
                  class="form-control"
                  id="height"
                  step="any"
                  :placeholder="$t('height')"
                  v-model="worker.height"
                />
                <span class="text-danger" v-if="errors.height">
                  {{ errors.height }}</span
                >
              </div>

              <!-- weight -->
              <div class="form-group col-md-6">
                <label for="weight" class="required">{{ $t("weight") }}</label>
                <input
                  type="number"
                  class="form-control"
                  id="weight"
                  :placeholder="$t('weight')"
                  v-model="worker.weight"
                  step="any"
                />
                <span class="text-danger" v-if="errors.weight">
                  {{ errors.weight }}</span
                >
              </div>

              <!-- birthday -->
              <div class="form-group col-md-6">
                <label for="birthday" class="required">{{
                  $t("birthday")
                }}</label>
                <div class="input-group">
                  <Datepicker
                    v-model="worker.birthday"
                    locale="ja"
                    :format="format"
                    :enableTimePicker="false"
                    :placeholder="$t('birthday')"
                    :clearable="false"
                    @update:modelValue="getSelectedDate"
                    autoApply
                  ></Datepicker>
                  <span class="text-danger" v-if="errors.birthday">
                    {{ errors.birthday }}</span
                  >
                </div>
              </div>

              <div class="form-group col-md-6" v-if="!workerId"></div>

              <div class="form-group col-md -6" v-if="workerId"></div>

              <!-- image -->
              <div class="form-group col-md-6">
                <label for="image">{{ $t("image") }}</label>
                <div class="">
                  <input
                    type="file"
                    id="image"
                    :placeholder="$t('image')"
                    @change="uploadImage"
                  />
                  <img
                    class="image-preview mt-2 mb-2"
                    v-if="worker.image"
                    :src="imagePreviewUrl"
                    alt="ahm"
                  />
                  <img
                    class="image-preview mt-2 mb-2"
                    v-if="editPreviewImage"
                    :src="editPreviewImage"
                    alt="ahm"
                  />
                </div>
                <span class="text-danger" v-if="errors.image">
                  {{ errors.image }}</span
                >
              </div>

              <div class="form-group col-md-6"></div>
              <!-- remarks -->
              <div class="form-group col-md-6">
                <label for="remarks">{{ $t("remarks") }}</label>
                <textarea
                  rows="4"
                  type="text"
                  class="form-control"
                  id="remarks"
                  :placeholder="$t('remarks')"
                  v-model="worker.remarks"
                ></textarea>
                <span class="text-danger" v-if="errors.remarks">
                  {{ errors.remarks }}</span
                >
              </div>
            </div>

            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button type="submit" class="btn btn-outline-primary">
                  <span
                    v-if="
                      formType === 'WorkerAdminFormCreate' ||
                      formType === 'WorkerClientFormCreate'
                    "
                  >
                    {{ $t("save") }}
                  </span>
                  <span v-else>
                    {{ $t("update") }}
                  </span>
                </button>
                <router-link
                  to="/admin/worker-management"
                  class="btn btn-outline-secondary ml-2"
                  v-if="role === 'admin'"
                >
                  {{ $t("cancel") }}
                </router-link>
                <router-link
                  to="/client/worker-management"
                  class="btn btn-outline-secondary ml-2"
                  v-if="role === 'client'"
                >
                  {{ $t("cancel") }}
                </router-link>
              </div>
            </div>

            <!-- password change for edit -->
            <form
              @submit.prevent="changePassword"
              class="form-row mt-4"
              v-if="workerId"
            >
              <!-- password -->
              <div class="form-group col-md-6">
                <label for="password">{{ $t("password") }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  :placeholder="$t('password')"
                  v-model="passwordChange.password"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.password">
                  {{ errors.password }}</span
                >
              </div>

              <!-- password confirmation -->
              <div class="form-group col-md-6">
                <label for="passwordConfirmation">{{
                  $t("confirmPassword")
                }}</label>
                <input
                  type="password"
                  class="form-control"
                  id="passwordConfirmation"
                  :placeholder="$t('confirmPassword')"
                  v-model="passwordChange.passwordConfirmation"
                />
                <small class="mt-2 d-block">{{ $t("passwordGuide") }}</small>
                <span class="text-danger" v-if="errors.passwordConfirmation">
                  {{ errors.passwordConfirmation }}</span
                >
              </div>

              <div class="d-flex justify-content-end w-100">
                <div class="d-flex">
                  <button class="btn btn-outline-primary" type="submit">
                    <span> {{ $t("updatePassword") }}</span>
                  </button>
                </div>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
    <!-- End col -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { onMounted, watchEffect } from "@vue/runtime-core";
import constants from "../../core/constants";
import { toastMessage } from "../../core/utils";
import { post, get } from "../../core/api/api-service";
import { store } from "../../core/store";
import i18n from "@/i18n";
import Select2 from "vue3-select2-component";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "WorkerForm",
  components: { Select2, Datepicker },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const passwordChange = ref({
      password: "",
      passwordConfirmation: "",
    });
    const worker = ref({
      loginId: "",
      workerName: "",
      email: "",
      fieldId: [],
      clientId: "",
      gender: "",
      height: "",
      weight: "",
      birthday: "",
      password: "",
      passwordConfirmation: "",
      image: "",
      remarks: "",
    });
    const imagePreviewUrl = ref("");
    const editPreviewImage = ref("");
    const date = ref(worker.value.birthday);
    const imageName = ref("");
    const errors = ref([]);
    const successMessage = ref("");
    const formType = ref("");
    const clientsList = ref([]);
    const clientsFieldList = ref([]);
    const fieldOptions = ref([]);
    const url = ref("");
    const baseUrl = ref("");
    const role = store.getters.role;
    const focusInput = ref(null);
    const workerId = route.params.id;
    const loading = ref(false);
    const pageName = ref(route.meta.breadcrumb[2]?.title);
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    };

    const selectField = (field) => {
      if (field.selected) {
        worker.value.fieldId.push(field.id);
      } else {
        worker.value.fieldId = worker.value.fieldId.filter((item) => {
          return item != field.id;
        });
      }
    };

    const changePassword = () => {
      errors.value = [];
      if (
        passwordChange.value.password &&
        passwordChange.value.passwordConfirmation &&
        passwordChange.value.password ===
          passwordChange.value.passwordConfirmation
      ) {
        if (role === "admin") {
          post(
            `/api/v1/admin/worker/${workerId}/change-password`,
            passwordChange.value
          )
            .then((res) => {
              successMessage.value = res.data.data.message;
              if (res.status === 200)
                router.push({ name: "AdminWorkerManagement" });
            })
            .then(() => {
              toastMessage(
                i18n.global.t("Success"),
                i18n.global.t("Password changed successfully.")
              );
            })
            .catch((err) => {
              const serverError = err.response.data.errors;
              serverError.forEach((error) => {
                const errorTitle = error?.source?.[0].split(':"')[1];
                errors.value[errorTitle] = i18n.global.t(error?.detail);
              });
            });
        } else if (role === "client") {
          post(
            `/api/v1/client/worker/${workerId}/change-password`,
            passwordChange.value
          )
            .then((res) => {
              successMessage.value = res.data.data.message;
              if (res.status === 200)
                router.push({ name: "ClientWorkerManagement" });
            })
            .then(() => {
              toastMessage(
                i18n.global.t("Success"),
                i18n.global.t("Password changed successfully.")
              );
            })
            .catch((err) => {
              const serverError = err.response.data.errors;
              serverError.forEach((error) => {
                const errorTitle = error?.source?.[0].split(':"')[1];
                errors.value[errorTitle] = i18n.global.t(error?.detail);
              });
            });
        }
      }
    };

    const uploadImage = (e) => {
      worker.value.image = e.target.files[0];
      imageName.value = e.target.files[0].name;
      if (e.target.files[0]) {
        imagePreviewUrl.value = URL.createObjectURL(worker.value.image);
        editPreviewImage.value = "";
      }
    };

    const getSelectedDate = (e) => {
      let obtainedDate = format(e);
      worker.value.birthday = obtainedDate;
    };

    const registerWorker = () => {
      errors.value = [];
      successMessage.value = "";

      if (!workerId && store.getters.role === "admin") {
        let formData = new FormData();
        for (let key in worker.value) {
          if (key !== "fieldId") {
            formData.append(key, worker.value[key]);
          } else {
            worker.value[key].forEach((fieldId) => {
              formData.append(`fieldId[]`, fieldId);
            });
          }
        }
        if (!worker.value.remarks) formData.append("remarks", "");
        if (!worker.value.image) formData.delete("image");

        post(constants.workerAdminUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "AdminWorkerManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("worker created successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (!workerId && store.getters.role === "client") {
        worker.value.clientId = "";
        let formData = new FormData();
        for (let key in worker.value) {
          if (key !== "fieldId") {
            formData.append(key, worker.value[key]);
          } else {
            worker.value[key].forEach((fieldId) => {
              formData.append(`fieldId[]`, fieldId);
            });
          }
        }
        if (!worker.value.remarks) formData.append("remarks", "");
        if (!worker.value.image) formData.delete("image");
        post(constants.workerClientUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "ClientWorkerManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("worker created successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (workerId && store.getters.role === "admin") {
        let formData = new FormData();
        formData.append("_method", "PUT");
        for (let key in worker.value) {
          if (key !== "fieldId") {
            formData.append(key, worker.value[key]);
          } else {
            worker.value[key].forEach((fieldId) => {
              formData.append(`fieldId[]`, fieldId);
            });
          }
        }
        if (!worker.value.remarks) formData.append("remarks", "");
        if (!worker.value.image) formData.delete("image");
        post(`${constants.workerAdminUrl}/${workerId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "AdminWorkerManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("worker edited successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
      if (workerId && store.getters.role === "client") {
        worker.value.clientId = "";
        let formData = new FormData();

        formData.append("_method", "PUT");
        for (let key in worker.value) {
          if (key !== "fieldId") {
            formData.append(key, worker.value[key]);
          } else {
            worker.value[key].forEach((fieldId) => {
              formData.append(`fieldId[]`, fieldId);
            });
          }
        }
        if (!worker.value.remarks) formData.append("remarks", "");
        if (!worker.value.image) formData.delete("image");
        post(`${constants.workerClientUrl}/${workerId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.status === 200)
              router.push({ name: "ClientWorkerManagement" });
          })
          .then(() => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t("worker edited successfully.")
            );
          })
          .catch((err) => {
            const serverError = err.response.data.errors;
            serverError.forEach((error) => {
              const errorTitle = error?.source?.[0].split(':"')[1];
              errors.value[errorTitle] = i18n.global.t(error?.detail);
            });
          });
      }
    };

    const getClientsField = async () => {
      let url = "";
      fieldOptions.value = [];
      role === "admin"
        ? (url = `${baseUrl.value}/get-fields/${worker.value.clientId}`)
        : (url = `${baseUrl.value}/get-fields`);
      const fieldsRes = await get(url);
      clientsFieldList.value = fieldsRes.data.data;
      clientsFieldList.value.map((field) => {
        fieldOptions.value.push({ id: field.id, text: field.name });
      });
      loading.value = false;
    };

    onMounted(async () => {
      if (workerId) {
        loading.value = true;
      }
      focusInput.value.focus();
      if (role === "admin") {
        url.value = constants.workerAdminUrl;
        baseUrl.value = constants.adminUrl;
      } else {
        url.value = constants.workerClientUrl;
        baseUrl.value = constants.clientUrl;
      }

      if (role === "admin") {
        const clientsRes = await get(`${baseUrl.value}/get-clients`);
        clientsList.value = clientsRes.data.data;
        if (router.currentRoute.value.name === "WorkerAdminFormEdit") {
          getWorkerInfo();
        }
      }

      if (role === "client") {
        if (workerId) {
          getWorkerInfo();
        }
        getClientsField();
      }
    });

    const getWorkerInfo = () => {
      get(`${url.value}/${workerId}`)
        .then((res) => {
          worker.value.workerName = res.data.data.worker_name;
          worker.value.clientId = res.data.data.client_id;
          worker.value.loginId = res.data.data.login_id;
          worker.value.gender = res.data.data.gender;
          worker.value.height = res.data.data.height;
          worker.value.weight = res.data.data.weight;
          worker.value.birthday = res.data.data.birthday;
          worker.value.password = res.data.data.password;
          worker.value.passwordConfirmation =
            res.data.data.passwordConfirmation;
          worker.value.email = res.data.data.email;
          worker.value.remarks = res.data.data.remarks;
          worker.value.fieldId = res.data.data.fields;
          editPreviewImage.value = res.data.data.image;
          if (role === "admin") getClientsField();
          return res.data.data.fields;
        })
        .then((fieldData) => {
          worker.value.fieldId = fieldData.map((field) => {
            return field.field_id;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    watchEffect(() => {
      formType.value = router.currentRoute.value.name;
    });

    return {
      date,
      changePassword,
      worker,
      errors,
      workerId,
      getClientsField,
      successMessage,
      registerWorker,
      formType,
      clientsList,
      clientsFieldList,
      fieldOptions,
      role,
      uploadImage,
      passwordChange,
      focusInput,
      imageName,
      loading,
      selectField,
      pageName,
      format,
      getSelectedDate,
      imagePreviewUrl,
      editPreviewImage,
    };
  },
};
</script>

<style>
.customized-file-input {
  position: relative;
}
.customized-file-input input {
  opacity: 0;
  width: 200px;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;
}
.customized-file-input a {
  opacity: 1;
  width: 200px;
  text-align: left;
  pointer-events: none;
}
/* select2 */
.select2-container {
  width: 100% !important;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
  line-height: 38px;
  margin-top: 0;
  line-height: 38px;
  color: #282828;
}
.select2-container .select2-selection--single {
  border: none;
  height: 38px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
  padding-left: 15px;
  color: #8a98ac;
}
.select2-container .select2-selection--multiple {
  border: none;
  min-height: 38px;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid #1ba4fd;
  outline: 0;
  background-color: #ffffff;
}
.select2-container--default .select2-selection--multiple {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 15px;
  vertical-align: bottom;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #1ba4fd;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px 10px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #ffffff;
  margin-right: 5px;
}
.select2-container--default .select2-selection--single {
  background-color: #ffffff;
  font-size: 16px;
  color: #8a98ac;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
  width: 30px;
  top: 0;
  right: 0;
}
.select2-container--default .select2-results__option {
  padding: 6px 15px;
  font-size: 14px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #1ba4fd !important;
  color: #ffffff;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.05);
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #282828;
}
.select2-dropdown {
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.image-preview {
  height: 100px;
}
</style>
