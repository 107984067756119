import axios from "axios";
import { store } from "../store";
// import { useRouter } from "vue-router";
import config from "../config";

export const httpBase = () => {
  // const router = useRouter();
  const normalHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters.token,
  };

  const http = axios.create({
    baseURL: config.baseAPIUrl,
    headers: { ...normalHeaders },
    responseType: "json",
  });


  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status) {
        localStorage.clear("token");
        if (store.getters.role === "admin") {
          window.location.href = "/admin/login";
        } else {
          window.location.href = "/client/login";
        }
      }
      if (404 === error.response.status) {
        console.error(error, "404");
      }
      if (500 === error.response.status) {
        console.error(error, "500");
      }
      if (429 === error.response.status) {
        console.error(error, "429");
      }
      return Promise.reject(error);
    }
  );

  return http;
};
