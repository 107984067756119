<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card m-b-5">
        <div class="card-header">
          <div class="row w-100">
            <div class="col-8">
              <h5 class="main-card-title">{{ $t("viewClients") }}</h5>
            </div>
            <div class="col-4 d-flex">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('search')"
                  v-model="keyword"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    id="button-addon-group"
                    @click="search"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
              <button
                class="btn btn-outline-secondary reset-button"
                @click="clearSearch"
              >
                {{ $t("reset") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div
              v-if="loading"
              class="d-flex justify-content-center"
              style="height: 100vh"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table
              class="table foo-filtering-table"
              data-filtering="true"
              v-else
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("clientName") }}
                    <span
                      class="sorting ml-2"
                      :class="{
                        'sort-up':
                          selectedField === 'client_name' &&
                          sortKeys.client_name,
                        'sort-down':
                          selectedField === 'client_name' &&
                          !sortKeys.client_name,
                      }"
                      @click="sortData('client_name')"
                    ></span>
                  </th>
                  <th>
                    {{ $t("loginId") }}
                  </th>
                  <th>
                    {{ $t("email") }}
                  </th>
                  <th>{{ $t("remarks") }}</th>
                  <th>{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="client of clientsList" :key="client.id">
                  <td>{{ client.client_name }}</td>
                  <td>{{ client.login_id }}</td>
                  <td>{{ client.email }}</td>
                  <td>{{ client.remarks }}</td>
                  <td>
                    <div class="btn-group btn-group-sm" style="float: none">
                      <router-link
                        :to="{
                          name: 'ClientFormEdit',
                          params: { id: client.id },
                        }"
                        class="
                          btn btn-sm btn-outline-primary
                          rounded
                          d-flex
                          align-items-center
                        "
                        style="float: none; margin: 5px"
                        ><span class="ti-pencil"></span
                      ></router-link>
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-danger rounded"
                        style="float: none; margin: 5px"
                        @click="removeClient(client.id, client.login_id)"
                      >
                        <span class="ti-trash"></span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div class="footable-paging">
          <div>
            <div
              class="footable-pagination-wrapper d-flex justify-content-center"
            >
              <ul class="pagination">
                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-if="pagination.previousPageUrl"
                >
                  <a class="footable-page-link btn" @click="getPrevPageData()"
                    >‹</a
                  >
                </li>

                <li
                  class="footable-page-nav disabled mr-4"
                  data-page="prev"
                  v-else
                >
                  <a class="footable-page-link text-secondary btn">‹</a>
                </li>
                <li
                  class="footable-page visible"
                  :class="{ active: pagination.activePage == page }"
                  v-for="(page, index) of totalPages"
                  :key="index"
                >
                  <a
                    class="footable-page-link btn"
                    @click="getPageData(page)"
                    >{{ page }}</a
                  >
                </li>
                <li
                  class="footable-page-nav ml-4"
                  v-if="pagination.nextPageUrl"
                >
                  <a class="footable-page-link btn" @click="getNextPageData()"
                    >›</a
                  >
                </li>
                <li class="footable-page-nav ml-4" v-else>
                  <a class="footable-page-link btn text-secondary">›</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect, computed } from "vue";
import { get } from "../../core/api/api-service";
import { store } from "../../core/store";

export default {
  name: "ClientTable",
  setup() {
    const clientsList = ref([]);
    const pagination = ref({
      totalCount: null,
      perPage: "20",
      currentPage: 1,
      nextPageUrl: "",
      activePage: 1,
      previousPageUrl: "",
    });
    const keyword = ref("");
    const sortKeys = ref({
      id: false,
      client_name: false,
      login_id: false,
      email: false,
    });
    const selectedField = ref("");

    const loading = ref(true);

    const addSerialNo = () => {
      for (let i = 0; i < clientsList.value.length; i++) {
        clientsList.value["sn"] = null;
        clientsList.value[i].sn =
          i + 1 + pagination.value.perPage * (pagination.value.activePage - 1);
      }
    };

    onMounted(() => {
      getClientData();
    });

    const getClientData = () => {
      loading.value = true;
      get(`/api/v1/clients?perPage=${pagination.value.perPage}`).then((res) => {
        clientsList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        selectedField.value = "";
        loading.value = false;
      });
    };

    const clearSearch = () => {
      keyword.value = "";
      getClientData();
    };

    const getPrevPageData = () => {
      if (pagination.value.previousPageUrl) {
        get(
          `${pagination.value.previousPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${selectedField?.value ? "id" : ""}&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          clientsList.value = res.data.data;
          pagination.value.activePage--;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const getPageData = (page) => {
      pagination.value.activePage = page;
      get(
        `/api/v1/clients?page=${pagination.value.activePage}&perPage=${
          pagination.value.perPage
        }&column=${selectedField?.value ? "id" : ""}&asc=${
          selectedField?.value ? sortKeys.value[selectedField.value] : ""
        }`
      ).then((res) => {
        clientsList.value = res.data.data;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.currentPage = res.data.paginationData.currentPage;
        addSerialNo();
        loading.value = false;
      });
    };

    const getNextPageData = () => {
      if (pagination.value.nextPageUrl) {
        get(
          `${pagination.value.nextPageUrl}&perPage=${
            pagination.value.perPage
          }&column=${selectedField?.value ? "id" : ""}&asc=${
            selectedField?.value ? sortKeys.value[selectedField.value] : ""
          }`
        ).then((res) => {
          clientsList.value = res.data.data;
          pagination.value.activePage++;
          pagination.value.previousPageUrl =
            res.data.paginationData.previousPageUrl;
          pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
          addSerialNo();
          loading.value = false;
        });
      }
    };

    const totalPages = computed(() => {
      return Math.ceil(pagination.value.totalCount / pagination.value.perPage);
    });

    watchEffect(() => {
      store.getters.modalMessage.success;
      if (store.getters.modalMessage.success === true) {
        get(`/api/v1/clients?perPage=${pagination.value.perPage}`).then(
          (res) => {
            clientsList.value = res.data.data;
            pagination.value.currentPage = 1;
            pagination.value.activePage = 1;
            addSerialNo();
            loading.value = false;
          }
        );
      }
    });

    const removeClient = (clientId, clientName) => {
      store.dispatch("updateModalMessage", {
        userName: clientName,
        userId: clientId,
        modalInactive: false,
        page: pagination.value.perPage,
      });
    };

    const search = () => {
      loading.value = true;
      get(
        `/api/v1/clients?perPage=${pagination.value.perPage}&keyword=${keyword.value}`
      ).then((res) => {
        clientsList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        selectedField.value = "";
        loading.value = false;
      });
    };

    const sortData = (sortKey) => {
      selectedField.value = sortKey;
      loading.value = true;
      for (let key in sortKeys.value) {
        if (key === sortKey) {
          sortKeys.value[key] = !sortKeys.value[key];
        } else {
          sortKeys.value[key] = false;
        }
      }
      let clientUrl = `/api/v1/clients?page=${pagination.value.currentPage}&perPage=${pagination.value.perPage}`;
      if (sortKeys.value.id) {
        clientUrl += `&column=id&asc=${sortKeys.value.id}`;
      }
      if (sortKeys.value.client_name) {
        clientUrl += `&column=id&asc=${sortKeys.value.client_name}`;
      }
      if (sortKeys.value.login_id) {
        clientUrl += `&column=id&asc=${sortKeys.value.login_id}`;
      }
      if (sortKeys.value.email) {
        clientUrl += `&column=id&asc=${sortKeys.value.email}`;
      }
      if (
        !sortKeys.value.id &&
        !sortKeys.value.client_name &&
        !sortKeys.value.login_id &&
        !sortKeys.value.email
      ) {
        clientUrl += "&column=id";
      }
      get(clientUrl).then((res) => {
        clientsList.value = res.data.data;
        pagination.value.totalCount = res.data.paginationData.totalCount;
        pagination.value.nextPageUrl = res.data.paginationData.nextPageUrl;
        pagination.value.previousPageUrl =
          res.data.paginationData.previousPageUrl;
        addSerialNo();
        loading.value = false;
      });
    };

    return {
      clientsList,
      removeClient,
      keyword,
      pagination,
      totalPages,
      getPageData,
      getPrevPageData,
      getNextPageData,
      addSerialNo,
      loading,
      search,
      sortKeys,
      selectedField,
      sortData,
      clearSearch,
    };
  },
};
</script>

<style scoped>
@import "../../assets/custom/custom-table.css";
</style>

<style>
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #e5e5e5;
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  -webkit-border-radius: 1ex;
}
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}
</style>
