<template>
  <ClientTable />
</template>

<script>
import ClientTable from "../../components/table/ClientTable.vue";
export default {
  name: "ClientManagement",
  components: {
    ClientTable,
  },
};
</script>