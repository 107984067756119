export const modalMessage = {
  state: {
    modalMessage: {
      userId: "",
      userName: "",
      modalInactive: null,
      page: "",
      success: null,
    },
  },
  mutations: {
    setModalMessage(state, modalMessage) {
      state.modalMessage = modalMessage;
    },
  },
  actions: {
    updateModalMessage({ commit }, modalMessage) {
      commit("setModalMessage", modalMessage);
    },
  },
  getters: {
    modalMessage: (state) => state.modalMessage,
  },
};
