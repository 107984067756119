<template>
  <div>
    <Toast />
    <AuthLayout>
      <form @submit.prevent="handleSubmit">
        <p class="mb-4">
          {{ $t("resetInstruction") }}
        </p>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            id="email"
            :placeholder="$t('email')"
            v-model="email"
          />
        </div>
        <div class="text-left mb-3" v-if="errors">
          <span class="text-danger"> {{ errors }}</span>
        </div>
        <button type="submit" class="btn btn-success btn-lg btn-block font-18">
          {{ $t("sendEmail") }}
        </button>
      </form>
      <p class="mb-0 mt-3">
        {{ $t("rememberPassword") }} <a href="login">{{ $t("login") }}</a>
      </p>
    </AuthLayout>
  </div>
</template>

<script>
import { ref } from "vue";
import AuthLayout from "../../components/admin/AuthLayout.vue";
import Toast from "../../components/common/Toast.vue";
import {
  forgetPassword,
  clientForgetPassword,
} from "../../core/api/auth-service";
import { toastMessage } from "../../core/utils";
import { useRouter } from "vue-router";
import i18n from "@/i18n";

export default {
  name: "ForgotPassword",
  components: {
    AuthLayout,
    Toast,
  },
  setup() {
    const router = useRouter();
    const email = ref("");
    const errors = ref("");

    const handleSubmit = () => {
      errors.value = "";
      if (!email.value) {
        errors.value = i18n.global.t("Please enter email address.");
      }
      if (email.value && router.currentRoute.value.name === "ForgotPassword") {
        forgetPassword(email.value)
          .then((res) => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t(res.data.data.detail)
            );
          })
          .catch((err) => {
            errors.value = i18n.global.t(err.response.data?.errors?.[0]?.title);
          });
      }

      if (
        email.value &&
        router.currentRoute.value.name === "ClientForgotPassword"
      ) {
        clientForgetPassword(email.value)
          .then((res) => {
            toastMessage(
              i18n.global.t("Success"),
              i18n.global.t(res.data.data.detail)
            );
          })
          .catch((err) => {
            errors.value = i18n.global.t(err.response.data?.errors?.[0]?.title);
          });
      }
    };

    return {
      email,
      errors,
      handleSubmit,
    };
  },
};
</script>