<template>
  <div class="row">
    <div class="col-lg-4 col-xl-3">
      <div class="email-leftbar">
        <div class="card fields-list-card m-b-30 bg-light pl-1 pr-1">
          <div class="card-body p-2">
            <div
              v-if="loading"
              class="d-flex justify-content-center mt-2"
              style="height: 50px"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <ul class="list-group" v-else>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  :placeholder="$t('search')"
                  v-model="searchText"
                />
                <div class="input-group-append">
                  <i class="ri-search-2-line"></i>
                </div>
              </div>
              <div class="search-buttons mt-1 d-flex">
                <div class="card w-100">
                  <button
                    class="btn btn-outline-primary mr-2 w-100"
                    @click="search"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
                <div class="card w-100">
                  <button
                    class="btn btn-outline-secondary w-100"
                    @click="clearSearch"
                  >
                    {{ $t("reset") }}
                  </button>
                </div>
              </div>
              <li
                class="list-group-item p-0"
                v-for="field of fieldsList"
                :key="field.id"
              >
                <div class="card rounded-0">
                  <button
                    class="btn text-left pt-3 pb-3"
                    :class="{ 'btn-info': activeBtnId === field.id }"
                    @click="getWorkersLocation(field.id)"
                  >
                    {{ field.field_name }}
                  </button>
                </div>
              </li>
            </ul>
            <div class="text-center" v-if="noData">
              {{ $t("Data not found") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-9">
      <div class="mb-2">
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '1m' }"
          @click="changeTime('1m')"
        >
          {{ $t("1m") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '5m' }"
          @click="changeTime('5m')"
        >
          {{ $t("5m") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '10m' }"
          @click="changeTime('10m')"
        >
          {{ $t("10m") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '30m' }"
          @click="changeTime('30m')"
        >
          {{ $t("30m") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '1h' }"
          @click="changeTime('1h')"
        >
          {{ $t("1h") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '5h' }"
          @click="changeTime('5h')"
        >
          {{ $t("5h") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '10h' }"
          @click="changeTime('10h')"
        >
          {{ $t("10h") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '1d' }"
          @click="changeTime('1d')"
        >
          {{ $t("1d") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '3d' }"
          @click="changeTime('3d')"
        >
          {{ $t("3d") }}
        </button>
        <button
          class="btn btn-outline-primary mr-2 mb-1"
          :class="{ 'btn-danger': buttonActive == '1w' }"
          @click="changeTime('1w')"
        >
          {{ $t("1w") }}
        </button>
      </div>
      <div class="card m-b-30">
        <div class="card-body p-0">
          <GMapMap
            ref="myMapRef"
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 600px"
            :key="key"
          >
            <GMapHeatmap :data="heatData" :options="options"></GMapHeatmap>
          </GMapMap>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*global google*/
import { ref, onMounted, watchEffect } from "vue";
import { get } from "../../core/api/api-service";
import { store } from "../../core/store";
import constants from "../../core/constants";

export default {
  name: "App",
  setup() {
    const role = store.getters.role;
    const fieldsList = ref([]);
    const fieldsUrl = ref("");
    const fieldsWorkerUrl = ref("");
    const totalFields = ref("");
    const markers = ref([]);
    const loading = ref(true);
    const activeBtnId = ref("");
    const sortByAsc = ref(false);
    const showSortIcon = ref(null);
    const searchText = ref("");
    const selectedField = ref([]);
    const timeLocationData = ref([]);
    const noData = ref(false);
    const options = ref({ radius: 20, opacity: 1, maxIntensity: 1 });
    const buttonActive = ref("1m");
    const date = ref(new Date());
    const key = ref(0);

    const center = ref({});
    const zoom = ref(16);
    const myMapRef = ref();
    const loadedData = ref([]);
    const heatData = ref([]);
    const fieldId = ref("");
    const changeTime = (timeDuration) => {
      buttonActive.value = timeDuration;
      get(
        `${fieldsWorkerUrl.value}/${
          fieldId.value
        }/list/${date.value.getFullYear()}-${
          date.value.getMonth() + 1 < 10 ? "0" : ""
        }${date.value.getMonth() + 1}-${
          date.value.getDate() + 1 < 10 ? "0" : ""
        }${date.value.getDate()}?type=${timeDuration}`
      ).then((res) => {
        heatData.value = [];
        res.data.forEach((worker) => {
          if (worker.locations) {
            let workerArr = worker.locations.map(
              (e) =>
                new google.maps.LatLng({
                  lat: Number(e.lat),
                  lng: Number(e.lng),
                })
            );

            heatData.value = [...heatData.value, ...workerArr];
          }
        });
      });
    };

    const search = () => {
      if (searchText.value) {
        if (sortByAsc.value) {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&keyword=${searchText.value}&name=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        } else {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&keyword=${searchText.value}&name=true&asc=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        }
      } else {
        if (sortByAsc.value) {
          get(`${fieldsUrl.value}?perPage=${totalFields.value}&name=true`).then(
            (res) => {
              fieldsList.value = res.data.data;
              loading.value = false;
            }
          );
        } else {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&name=true&asc=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        }
      }
    };

    const clearSearch = () => {
      searchText.value = "";
      if (sortByAsc.value) {
        get(`${fieldsUrl.value}?perPage=${totalFields.value}&name=true`).then(
          (res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          }
        );
      }
    };

    onMounted(async () => {
      setURL();
      showSortIcon.value = null;
      const fieldsRes = await get(fieldsUrl.value);
      totalFields.value = fieldsRes.data.paginationData.totalCount;
      getFieldsData();
    });

    watchEffect(() => {
      zoom.value;
      getWorkersLocation;
    });

    const setURL = () => {
      role === "admin"
        ? (fieldsWorkerUrl.value = constants.adminAnalysisURL)
        : (fieldsWorkerUrl.value = constants.clientAnalysisURL);
      role === "admin"
        ? (fieldsUrl.value = constants.fieldAdminUrl)
        : (fieldsUrl.value = constants.fieldClientUrl);
    };

    const getFieldsData = () => {
      get(`${fieldsUrl.value}?perPage=${totalFields.value}`).then((res) => {
        fieldsList.value = res.data.data;
        if (res.data.data.length) {
          selectedField.value = res.data.data[0];
          activeBtnId.value = selectedField.value.id;
          center.value.lng = Number(selectedField.value.longitude);
          center.value.lat = Number(selectedField.value.latitude);
          getWorkersLocation(selectedField.value.id);
          loading.value = false;
        } else {
          noData.value = true;
          loading.value = false;
        }
        loading.value = false;
      });
    };
    const forceUpdate = () => {
      key.value++;
    };
    const getWorkersLocation = (id) => {
      zoom.value = 16;
      forceUpdate();
      buttonActive.value = "1m";
      fieldId.value = id;
      activeBtnId.value = id;
      selectedField.value = fieldsList.value.filter((item) => {
        return item.id == id;
      });
      center.value.lng = Number(selectedField.value[0].longitude);
      center.value.lat = Number(selectedField.value[0].latitude);

      get(
        `${fieldsWorkerUrl.value}/${id}/list/${date.value.getFullYear()}-${
          date.value.getMonth() + 1 < 10 ? "0" : ""
        }${date.value.getMonth() + 1}-${
          date.value.getDate() + 1 < 10 ? "0" : ""
        }${date.value.getDate()}?type=1m`
      ).then((res) => {
        heatData.value = [];
        res.data.forEach((worker) => {
          if (worker.locations) {
            let workerArr = worker.locations.map(
              (e) =>
                new google.maps.LatLng({
                  lat: Number(e.lat),
                  lng: Number(e.lng),
                })
            );

            heatData.value = [...heatData.value, ...workerArr];
          }
        });
      });
    };

    return {
      center,
      zoom,
      heatData,
      myMapRef,
      loadedData,
      fieldsList,
      getWorkersLocation,
      markers,
      loading,
      activeBtnId,
      sortByAsc,
      searchText,
      selectedField,
      timeLocationData,
      noData,
      options,
      showSortIcon,
      search,
      clearSearch,
      changeTime,
      fieldId,
      buttonActive,
      date,
      key,
      forceUpdate,
    };
  },
};
</script>

<style scoped>
.vue-map-container {
  height: 690px;
}
.fields-list-card {
  height: 736px;
  overflow-y: auto;
}
.email-leftbar ul li {
  padding: 0 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.input-group-append {
  position: absolute;
  top: 50%;
  right: 10px !important;
  transform: translateY(-50%);
  z-index: 10;
}
.sort-btn,
.btn-clear {
  background-color: #d9d9e5;
}
</style>
