<template>
  <div class="chart-wrap">
    <div class="mb-2 mt-2 d-flex">
      <div class="form-group mb-0 mr-2">
        <div class="input-group">
          <Datepicker
            v-model="date"
            locale="ja"
            :format="format"
            :enableTimePicker="false"
            :clearable="false"
            @update:modelValue="getSelectedDate"
            autoApply
          ></Datepicker>
        </div>
      </div>
      <button
        class="btn btn-outline-primary mr-2"
        :class="{ 'btn-danger': buttonActive == 'year' }"
        @click="getFullYearData"
      >
        {{ $t("year") }}
      </button>
      <button
        class="btn btn-outline-primary mr-2"
        :class="{ 'btn-danger': buttonActive == 'month' }"
        @click="getMonthData"
      >
        {{ $t("month") }}
      </button>
      <button
        class="btn btn-outline-primary mr-2"
        :class="{ 'btn-danger': buttonActive == 'week' }"
        @click="getWeekData"
      >
        {{ $t("week") }}
      </button>
      <button
        class="btn btn-outline-primary mr-2"
        :class="{ 'btn-danger': buttonActive == 'today' }"
        @click="getTodayData"
      >
        {{ $t("today") }}
      </button>
    </div>
    <vue3-chart-js
      ref="chartRef"
      :id="lineChart.id"
      :type="lineChart.type"
      :data="lineChart.data"
      :options="lineChart.options"
      height="90"
    ></vue3-chart-js>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { get } from "../../core/api/api-service";
import constants from "../../core/constants";
import { useRoute } from "vue-router";
import { store } from "../../core/store";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
    Datepicker,
  },
  setup() {
    const route = useRoute();
    const workerId = route.params.id;
    const chartRef = ref(null);
    const date = ref(new Date());

    let chartTime = [];
    let chartValue = [];
    const buttonActive = ref("today");
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const updateTodayChart = (res) => {
      chartTime = [];
      chartValue = [];
      res.data?.activities_intraday.forEach((elem) => {
        chartValue.push(elem.value);
        chartTime.push(elem.time);
      });
      lineChart.data.labels = chartTime;
      lineChart.data.datasets[0].data = chartValue;
      chartRef.value.update();
    };

    const getTodayData = () => {
      buttonActive.value = "today";
      if (store.getters.role === "admin") {
        get(
          `${constants.workerHeartBeatAdminUrl}/${workerId}/heart_rate/${date.value}`
        ).then((res) => {
          updateTodayChart(res);
        });
      }
      if (store.getters.role === "client") {
        get(
          `${constants.workerHeartBeatClientUrl}/${workerId}/heart_rate/${date.value}`
        ).then((res) => {
          updateTodayChart(res);
        });
      }
    };

    const getSelectedDate = (e) => {
      let obtainedDate = format(e);
      date.value = obtainedDate;
      if (buttonActive.value == "today") {
        getTodayData();
      }
      if (buttonActive.value == "week") {
        getWeekData();
      }
      if (buttonActive.value == "month") {
        getMonthData();
      }
      if (buttonActive.value == "year") {
        getFullYearData();
      }
    };

    const updateWeekChart = (res) => {
      chartTime = [];
      chartValue = [];
      res.data?.activities.forEach((elem) => {
        chartValue.push(elem.value);
        chartTime.push(elem.date);
      });
      lineChart.data.labels = chartTime;
      lineChart.data.datasets[0].data = chartValue;
      chartRef.value.update();
    };

    const getWeekData = () => {
      buttonActive.value = "week";
      if (store.getters.role === "admin") {
        get(
          `${constants.workerHeartBeatAdminUrl}/${workerId}/heart_rate/${date.value}/1w`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
      if (store.getters.role === "client") {
        get(
          `${constants.workerHeartBeatClientUrl}/${workerId}/heart_rate/${date.value}/1w`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
    };

    const getMonthData = () => {
      buttonActive.value = "month";
      if (store.getters.role === "admin") {
        get(
          `${constants.workerHeartBeatAdminUrl}/${workerId}/heart_rate/${date.value}/1m`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
      if (store.getters.role === "client") {
        get(
          `${constants.workerHeartBeatClientUrl}/${workerId}/heart_rate/${date.value}/1m`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
    };

    const getFullYearData = () => {
      buttonActive.value = "year";
      if (store.getters.role === "admin") {
        get(
          `${constants.workerHeartBeatAdminUrl}/${workerId}/heart_rate/${date.value}/1y`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
      if (store.getters.role === "client") {
        get(
          `${constants.workerHeartBeatClientUrl}/${workerId}/heart_rate/${date.value}/1y`
        ).then((res) => {
          updateWeekChart(res);
        });
      }
    };

    const lineChart = {
      id: "line",
      type: "line",
      data: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#E46651"],
            borderColor: ["#E46651"],
            data: [],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        tension: 0.4,
      },
    };

    onMounted(() => {
      date.value = new Date().toISOString().split("T")[0];
      getTodayData();
    });

    return {
      lineChart,
      chartRef,
      chartValue,
      chartTime,
      date,
      format,
      getTodayData,
      getWeekData,
      getMonthData,
      getFullYearData,
      buttonActive,
      getSelectedDate,
    };
  },
};
</script>