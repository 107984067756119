<template>
  <WorkerTable />
</template>

<script>
import WorkerTable from "../../components/table/WorkerTable.vue";
export default {
  name: "WorkerManagement",
  components: {
    WorkerTable,
  },
};
</script>