<template>
  <router-view />
</template>

<script></script>

<style lang="scss">
@import "/vue3-date-time-picker/dist/main.css";
@import "assets/plugins/switchery/switchery.min.css";
@import "assets/plugins/apexcharts/apexcharts.css";
@import "assets/plugins/slick/slick.css";
@import "assets/plugins/slick/slick-theme.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/icons.css";
@import "assets/css/flag-icon.min.css";
@import "assets/plugins/datepicker/datepicker.min.css";
@import "assets/css/style.css";
</style>
