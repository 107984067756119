export const toasterMessage = {
  state: {
    toastMessage: {
      title: "",
      message: "",
    },
  },
  mutations: {
    setMessage(state, toastMessage) {
      state.toastMessage = toastMessage;
    },
  },
  actions: {
    updateMessage({ commit }, toastMessage) {
      commit("setMessage", toastMessage);
    },
  },
  getters: {
    toastMessage: (state) => state.toastMessage,
  },
};
