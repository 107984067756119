<template>
  <FieldTable />
</template>

<script>
import FieldTable from "../../components/table/FieldTable.vue";
export default {
  name: "FieldManagement",
  components: {
    FieldTable,
  },
};
</script>