<template>
  <div class="row">
    <div class="col-lg-4 col-xl-3">
      <div class="email-leftbar">
        <div class="card fields-list-card m-b-30 bg-light pl-1 pr-1">
          <div class="card-body p-2">
            <div
              v-if="loading"
              class="d-flex justify-content-center mt-2"
              style="height: 50px"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <ul class="list-group" v-else>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  :placeholder="$t('search')"
                  v-model="searchText"
                />
                <div class="input-group-append">
                  <i class="ri-search-2-line"></i>
                </div>
              </div>
              <div class="search-buttons mt-1 d-flex">
                <div class="card w-100">
                  <button
                    class="btn btn-outline-primary mr-2 w-100"
                    @click="search"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
                <div class="card w-100">
                  <button
                    class="btn btn-outline-secondary w-100"
                    @click="clearSearch"
                  >
                    {{ $t("reset") }}
                  </button>
                </div>
              </div>
              <li
                class="list-group-item p-0"
                v-for="field of fieldsList"
                :key="field.id"
              >
                <div class="card rounded-0">
                  <button
                    class="btn text-left pt-3 pb-3"
                    :class="{ 'btn-info': activeBtnId === field.id }"
                    @click="getWorkersLocation(field.id)"
                  >
                    {{ field.field_name }}
                  </button>
                </div>
              </li>
            </ul>
            <div class="text-center" v-if="noData">
              {{ $t("Data not found") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-9">
      <div class="card m-b-30">
        <div class="card-body p-0">
          <GMapMap
            :center="center"
            :zoom="zoom"
            map-type-id="terrain"
            style="width: 100vw; height: 900px"
            :key="key"
          >
            <GMapCluster :zoomOnClick="true">
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center = m.position"
              />
            </GMapCluster>
          </GMapMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";
import { get } from "../../core/api/api-service";
import { store } from "../../core/store";
import constants from "../../core/constants";

export default {
  name: "Location",
  setup() {
    const role = store.getters.role;
    const fieldsList = ref([]);
    const fieldsUrl = ref("");
    const fieldsWorkerUrl = ref("");
    const totalFields = ref("");
    const center = ref({});
    const markers = ref([]);
    const loading = ref(true);
    const activeBtnId = ref("");
    const sortByAsc = ref(false);
    const showSortIcon = ref(null);
    const searchText = ref("");
    const selectedField = ref([]);
    const noData = ref(null);
    const zoom = ref(16);
    const key = ref(0);

    const search = () => {
      if (searchText.value) {
        if (sortByAsc.value) {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&keyword=${searchText.value}&name=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        } else {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&keyword=${searchText.value}&name=true&asc=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        }
      } else {
        if (sortByAsc.value) {
          get(`${fieldsUrl.value}?perPage=${totalFields.value}&name=true`).then(
            (res) => {
              fieldsList.value = res.data.data;
              loading.value = false;
            }
          );
        } else {
          get(
            `${fieldsUrl.value}?perPage=${totalFields.value}&name=true&asc=true`
          ).then((res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          });
        }
      }
    };

    const clearSearch = () => {
      searchText.value = "";
      if (sortByAsc.value) {
        get(`${fieldsUrl.value}?perPage=${totalFields.value}&name=true`).then(
          (res) => {
            fieldsList.value = res.data.data;
            loading.value = false;
          }
        );
      }
    };

    onMounted(async () => {
      setURL();
      const fieldsRes = await get(fieldsUrl.value);
      totalFields.value = fieldsRes.data.paginationData.totalCount;
      getFieldsData();
      let selectedWorkerId = fieldsRes.data.data[0].id;
      get(`${fieldsWorkerUrl.value}/${selectedWorkerId}`).then((res) => {
        markers.value = [];
        res.data.data.forEach((worker) => {
          markers.value.push({
            position: {
              lat: Number(worker.location.split(",")[0]),
              lng: Number(worker.location.split(",")[1]),
            },
          });
        });
      });
    });

    watchEffect(() => {
      getWorkersLocation;
    });

    const setURL = () => {
      role === "admin"
        ? (fieldsWorkerUrl.value = constants.adminFieldLocationURL)
        : (fieldsWorkerUrl.value = constants.clientFieldLocationURL);
      role === "admin"
        ? (fieldsUrl.value = constants.fieldAdminUrl)
        : (fieldsUrl.value = constants.fieldClientUrl);
    };

    const getFieldsData = () => {
      get(`${fieldsUrl.value}?perPage=${totalFields.value}`).then((res) => {
        fieldsList.value = res.data.data;
        if (res.data.data.length) {
          selectedField.value = res.data.data[0];
          activeBtnId.value = selectedField.value.id;
          center.value.lng = Number(selectedField.value.longitude);
          center.value.lat = Number(selectedField.value.latitude);
          loading.value = false;
        } else {
          noData.value = true;
          loading.value = false;
        }
        loading.value = false;
      });
    };
    const forceUpdate = () => {
      key.value++;
    };
    const getWorkersLocation = (id) => {
      zoom.value = 16;
      forceUpdate();
      activeBtnId.value = id;
      selectedField.value = fieldsList.value.filter((item) => {
        return item.id == id;
      });
      center.value.lng = Number(selectedField.value[0].longitude);
      center.value.lat = Number(selectedField.value[0].latitude);

      get(`${fieldsWorkerUrl.value}/${id}`).then((res) => {
        markers.value = [];
        res.data.data.forEach((worker) => {
          markers.value.push({
            position: {
              lat: Number(worker.location.split(",")[0]),
              lng: Number(worker.location.split(",")[1]),
            },
          });
        });
      });
    };

    return {
      fieldsList,
      getWorkersLocation,
      center,
      markers,
      loading,
      activeBtnId,
      sortByAsc,
      searchText,
      selectedField,
      noData,
      showSortIcon,
      search,
      zoom,
      clearSearch,
      key,
      forceUpdate,
    };
  },
};
</script>

<style scoped>
.vue-map-container {
  height: 690px;
}
.fields-list-card {
  height: 692px;
  overflow-y: auto;
}
.email-leftbar ul li {
  padding: 0 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.input-group-append {
  position: absolute;
  top: 50%;
  right: 10px !important;
  transform: translateY(-50%);
  z-index: 10;
}
.sort-btn,
.btn-clear {
  background-color: #d9d9e5;
}
</style>
