<template>
  <div id="containerbar" class="containerbar">
    <!-- Start Container -->
    <div class="container">
      <div class="auth-box login-box">
        <!-- Start row -->
        <div class="row no-gutters align-items-center justify-content-center">
          <!-- Start col -->
          <div class="col-md-6 col-lg-5">
            <!-- Start Auth Box -->
            <div class="auth-box-right">
              <div class="card">
                <div class="card-body">
                  <div class="form-head">
                    <router-link to="#" class="logo">
                      <img
                        src="/assets/logo.png"
                        class="img-fluid"
                        alt="logo"
                      />
                    </router-link>
                    <h4>{{ $t("agriHealthManager") }}</h4>
                  </div>
                  <slot />
                </div>
              </div>
            </div>
            <!-- End Auth Box -->
          </div>
          <!-- End col -->
        </div>
        <!-- End row -->
      </div>
    </div>
    <!-- End Container -->
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>