import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./core/store/index";
import i18n from "./i18n";
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App).use(i18n).use(store).use(router).use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB758XD3PrnHgbyPFJbg27BKRSh0XGhT9M",
    libraries: "visualization",
    language: "ja",
    region: "JP",
  }
}).mount("#app");
