import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/admin/Login.vue";
import AdminManagement from "../views/admin/AdminManagement.vue";
import ClientManagement from "../views/admin/ClientManagement.vue";
import FieldManagement from "../views/admin/FieldManagement.vue";
import FieldForm from "../views/admin/FieldForm.vue";
import WorkerManagement from "../views/admin/WorkerManagement.vue";
import WorkerForm from "../views/admin/WorkerForm.vue";
import WorkerDetails from "../views/admin/WorkerDetails.vue";
import ForgotPassword from "../views/admin/ForgotPassword.vue";
import ResetPassword from "../views/admin/ResetPassword.vue";
import AdminForm from "../views/admin/AdminForm.vue";
import ClientForm from "../views/admin/ClientForm.vue";
import Location from "../views/admin/Location.vue";
import ConductorAnalysis from "../views/admin/ConductorAnalysis.vue";
import NotFound from "../components/common/404.vue";
import { store } from "../core/store/index";
import i18n from "@/i18n";

const protectedRoutes = (to, from, next) => {
  if (!store.state.authentication.authenticated) {
    next(`/${store.state.authentication.role}/login`);
  } else {
    next();
  }
};

const guestRoutes = (to, from, next) => {
  if (!store.state.authentication.authenticated) {
    next();
  } else {
    next(`/`);
  }
};

let role = localStorage?.role;
let clientUrl = window.location.href.indexOf("/client/") > -1;
let adminUrl = window.location.href.indexOf("/admin/") > -1;
if (role == "admin") {
  if (clientUrl) {
    localStorage.clear("token");
    window.location.href = "/client/login";
  }
}
if (role == "client") {
  if (adminUrl) {
    localStorage.clear("token");
    window.location.href = "/admin/login";
  }
}

if (adminUrl) {
  localStorage.setItem("loginPageType", "admin");
  localStorage.setItem("defaultPage", "admin");
} else if (clientUrl) {
  localStorage.setItem("loginPageType", "client");
  localStorage.setItem("defaultPage", "field");
}

const routes = [
  {
    path: "/admin/login",
    name: "Login",
    component: Login,
    beforeEnter: guestRoutes,
  },
  {
    path: "/client/login",
    name: "ClientLogin",
    component: Login,
    beforeEnter: guestRoutes,
  },
  {
    path: "/admin/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: guestRoutes,
  },
  {
    path: "/client/forgot-password",
    name: "ClientForgotPassword",
    component: ForgotPassword,
    beforeEnter: guestRoutes,
  },
  {
    path: "/admin/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    beforeEnter: guestRoutes,
  },
  {
    path: "/client/reset-password",
    name: "ClientResetPassword",
    component: ResetPassword,
    beforeEnter: guestRoutes,
  },
  {
    path: "/client/home",
    name: "ClientHome",
    component: Home,
    beforeEnter: protectedRoutes,
    children: {
      path: "",
      component: Home,
      meta: {
        breadcrumb: [{ title: i18n.global.t("home"), pathName: "Home" }],
      },
    },
  },
  { path: "/404", name: "NotFound", component: NotFound },
  { path: "/:catchAll(.*)", redirect: "/404" },
  { path: "/client", redirect: "/client/login" },
  { path: "/admin", redirect: "/admin/login" },
  {
    path: "",
    name: "Home",
    component: Home,
    meta: {
      breadcrumb: [{ title: i18n.global.t("home"), pathName: "Home" }],
      breadcrumbIcon: "mdi mdi-home",
    },
    redirect: `/${localStorage?.loginPageType}/${localStorage?.defaultPage}-management`,
    beforeEnter: protectedRoutes,
    children: [
      {
        path: "/admin/admin-management",
        name: "AdminManagement",
        component: AdminManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("adminManagement"),
              pathName: "AdminManagement",
            },
          ],
          breadcrumbIcon: "ri-user-2-fill",
          adminAddBtn: true,
        },
      },
      {
        path: "/admin/admin-management/create",
        name: "AdminFormCreate",
        component: AdminForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("adminManagement"),
              pathName: "AdminManagement",
            },
            {
              title: i18n.global.t("createAdmin"),
              pathName: "AdminFormCreate",
            },
          ],
          breadcrumbIcon: "ri-user-2-fill",
        },
      },
      {
        path: "/admin/admin-management/:id/edit",
        name: "AdminFormEdit",
        component: AdminForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("adminManagement"),
              pathName: "AdminManagement",
            },
            { title: i18n.global.t("adminEdit"), pathName: "AdminFormEdit" },
          ],
          breadcrumbIcon: "ri-user-2-fill",
        },
      },
      {
        path: "/admin/client-management",
        name: "ClientManagement",
        component: ClientManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("clientManagement"),
              pathName: "ClientManagement",
            },
          ],
          breadcrumbIcon: "ri-user-6-fill",
          adminAddBtn: true,
        },
      },
      {
        path: "/admin/client-management/create",
        name: "ClientFormCreate",
        component: ClientForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("clientManagement"),
              pathName: "ClientManagement",
            },
            {
              title: i18n.global.t("createClient"),
              pathName: "ClientFormCreate",
            },
          ],
          breadcrumbIcon: "ri-user-6-fill",
        },
      },
      {
        path: "/admin/client-management/:id/edit",
        name: "ClientFormEdit",
        component: ClientForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("clientManagement"),
              pathName: "ClientManagement",
            },
            { title: i18n.global.t("clientEdit"), pathName: "ClientFormEdit" },
          ],
          breadcrumbIcon: "ri-user-6-fill",
        },
      },
      {
        path: "/admin/field-management",
        name: "AdminFieldManagement",
        component: FieldManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "AdminFieldManagement",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
          adminAddBtn: true,
        },
      },
      {
        path: "/admin/field-management/create",
        name: "FieldAdminFormCreate",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "AdminFieldManagement",
            },
            {
              title: i18n.global.t("createField"),
              pathName: "FieldAdminFormCreate",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
        },
      },
      {
        path: "/admin/field-management/:id/edit",
        name: "FieldAdminFormEdit",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "AdminFieldManagement",
            },
            {
              title: i18n.global.t("fieldEdit"),
              pathName: "FieldAdminFormEdit",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
        },
      },
      {
        path: "/admin/worker-management",
        name: "AdminWorkerManagement",
        component: WorkerManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "AdminWorkerManagement",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
          adminAddBtn: true,
        },
      },
      {
        path: "/admin/worker-management/create",
        name: "WorkerAdminFormCreate",
        component: WorkerForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "AdminWorkerManagement",
            },
            {
              title: i18n.global.t("createWorker"),
              pathName: "WorkerAdminFormCreate",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
      {
        path: "/admin/worker-management/:id/edit",
        name: "WorkerAdminFormEdit",
        component: WorkerForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "AdminWorkerManagement",
            },
            {
              title: i18n.global.t("workerEdit"),
              pathName: "WorkerAdminFormEdit",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
      {
        path: "/client/field-management",
        name: "ClientFieldManagement",
        component: FieldManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "ClientFieldManagement",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
          adminAddBtn: true,
        },
      },
      {
        path: "/client/field-management/create",
        name: "FieldClientFormCreate",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "ClientFieldManagement",
            },
            {
              title: i18n.global.t("createField"),
              pathName: "FieldClientFormCreate",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
        },
      },
      {
        path: "/client/field-management/:id/edit",
        name: "FieldClientFormEdit",
        component: FieldForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("fieldManagement"),
              pathName: "ClientFieldManagement",
            },
            {
              title: i18n.global.t("fieldEdit"),
              pathName: "FieldClientFormEdit",
            },
          ],
          breadcrumbIcon: "ri-layout-4-line",
        },
      },
      {
        path: "/client/worker-management",
        name: "ClientWorkerManagement",
        component: WorkerManagement,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "ClientWorkerManagement",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
          adminAddBtn: true,
        },
      },
      {
        path: "/client/worker-management/create",
        name: "WorkerClientFormCreate",
        component: WorkerForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "ClientWorkerManagement",
            },
            {
              title: i18n.global.t("createWorker"),
              pathName: "WorkerClientFormCreate",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
      {
        path: "/client/worker-management/:id/edit",
        name: "WorkerClientFormEdit",
        component: WorkerForm,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "ClientWorkerManagement",
            },
            {
              title: i18n.global.t("workerEdit"),
              pathName: "WorkerClientFormEdit",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
      {
        path: "/admin/worker-management/:id/worker-details",
        name: "WorkerDetails",
        component: WorkerDetails,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "ClientWorkerManagement",
            },
            {
              title: i18n.global.t("workerDetails"),
              pathName: "WorkerDetails",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
      {
        path: "/admin/location",
        name: "LocationPage",
        component: Location,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("locationManagement"),
              pathName: "LocationPage",
            },
          ],
          breadcrumbIcon: "ri-map-pin-2-fill",
        },
      },
      {
        path: "/admin/conductor-analysis",
        name: "ConductorAnalysis",
        component: ConductorAnalysis,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("conductorAnalysis"),
              pathName: "LocationPage",
            },
          ],
          breadcrumbIcon: "ri-map-pin-line",
        },
      },
      {
        path: "/client/conductor-analysis",
        name: "ClientConductorAnalysis",
        component: ConductorAnalysis,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("conductorAnalysis"),
              pathName: "LocationPage",
            },
          ],
          breadcrumbIcon: "ri-map-pin-line",
        },
      },
      {
        path: "/client/location",
        name: "clientLocationPage",
        component: Location,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("locationManagement"),
              pathName: "LocationPage",
            },
          ],
          breadcrumbIcon: "ri-map-pin-2-fill",
        },
      },
      {
        path: "/client/worker-management/:id/worker-details",
        name: "clientWorkerDetails",
        component: WorkerDetails,
        meta: {
          breadcrumb: [
            { title: i18n.global.t("home"), pathName: "Home" },
            {
              title: i18n.global.t("workerManagement"),
              pathName: "ClientWorkerManagement",
            },
            {
              title: i18n.global.t("workerDetails"),
              pathName: "clientWorkerDetails",
            },
          ],
          breadcrumbIcon: "ri-user-star-fill",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
