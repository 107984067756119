<template>
  <div>
    <h5 class="main-card-title mb-4">
      {{ pageName }}
    </h5>
    <div class="row">
      <!-- Start col -->
      <div class="col-lg-3">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="profilebox py-4 text-center">
              <img
                :src="workerDetails.image"
                class="img-fluid mb-3 rounded-circle"
                alt="profile"
                v-if="workerDetails.image"
              />
              <img
                src="/assets/images/users/profile.svg"
                class="img-fluid mb-3"
                alt="profile"
                v-else
              />
              <div class="profilename">
                <h5 class="text-capitalize">{{ workerDetails.worker_name }}</h5>
                <p class="text-muted my-3">
                  <router-link :to="workerEditLink"
                    ><i class="ri-pencil-line mr-2"></i
                    >{{ $t("editDetails") }}</router-link
                  >
                </p>
              </div>
            </div>
            <p>ID: {{ workerDetails.id }}</p>
            <p>
              {{ $t("field") }}:
              <span
                v-for="(field, index) in workerDetails.fields"
                :key="index"
                >{{
                  index != workerDetails.fields.length - 1
                    ? `${field.field_name}, `
                    : field.field_name
                }}</span
              >
            </p>
            <p>
              {{ $t("gender") }}:
              <span v-if="workerDetails.gender == '0'">{{
                $t("unknown")
              }}</span>
              <span v-if="workerDetails.gender == '1'">{{ $t("male") }}</span>
              <span v-if="workerDetails.gender == '2'">{{ $t("female") }}</span>
            </p>
            <p>{{ $t("height") }}: {{ workerDetails.height }}</p>
            <p>{{ $t("weight") }}: {{ workerDetails.weight }}</p>
            <p>{{ $t("birthday") }}: {{ workerDetails.birthday }}</p>
          </div>
        </div>
      </div>
      <!-- End col -->
      <!-- Start col -->
      <div class="col-lg-9">
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="card-title">{{ $t("heartRate") }}</h5>
            <HeartBeatChart />
          </div>
        </div>
        <div class="card m-b-30">
          <div class="card-header">
            <h5 class="card-title">{{ $t("currentLocation") }}</h5>
          </div>
          <div class="card-body">
            <iframe
              :src="mapLocation"
              height="280"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              class="w-100"
              async
              defer
            ></iframe>
          </div>
        </div>
      </div>
      <!-- End col -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { watchEffect } from "@vue/runtime-core";
import { get } from "../../core/api/api-service";
import { useRoute } from "vue-router";
import constants from "../../core/constants";
import HeartBeatChart from "../../components/common/HeartBeatChart.vue";
import { store } from "../../core/store";

export default {
  name: "WorkerDetails",
  components: {
    HeartBeatChart,
  },
  setup() {
    const route = useRoute();
    const workerDetails = ref([]);
    const workerId = route.params.id;
    const workerEditLink = ref(
      `/${store.getters.role}/worker-management/${workerId}/edit`
    );
    const location = ref("");
    const mapLocation = ref("");
    const pageName = ref(route.meta.breadcrumb[2]?.title);

    watchEffect(() => {
      mapLocation.value;
      if (store.getters.role === "admin") {
        get(`${constants.workerAdminUrl}/${workerId}`).then((res) => {
          workerDetails.value = res.data.data;
          location.value = workerDetails.value?.location;
          mapLocation.value = `https://maps.google.com/maps?q=${location.value}&key=AIzaSyB758XD3PrnHgbyPFJbg27BKRSh0XGhT9M&hl=ja&z=14&amp;&output=embed`;
        });
      }
      if (store.getters.role === "client") {
        get(`${constants.workerClientUrl}/${workerId}`).then((res) => {
          workerDetails.value = res.data.data;
          location.value = workerDetails.value?.location;
          mapLocation.value = `https://maps.google.com/maps?q=${location.value}&key=AIzaSyB758XD3PrnHgbyPFJbg27BKRSh0XGhT9M&hl=ja&z=14&amp;&output=embed`;
        });
      }
    });
    return {
      workerDetails,
      workerEditLink,
      mapLocation,
      location,
      pageName,
    };
  },
};
</script>

<style scoped>
.vue-map-container {
  height: 240px;
}
</style>