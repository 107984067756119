<template>
  <div>
    <Toast />
    <AuthLayout v-if="validateTokenAPILoaded && isTokenValid">
      <form @submit.prevent="submitResetPasswordForm">
        <h4 class="text-primary my-4">{{ $t("resetPassword") }}</h4>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            id="password"
            :placeholder="$t('password')"
            required
            v-model="passwordForm.password"
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            id="confirm-password"
            :placeholder="$t('confirmPassword')"
            required
            v-model="passwordForm.passwordConfirmation"
          />
        </div>
        <div class="text-left mb-3" v-if="errors">
          <span class="text-danger"> {{ errors }}</span>
        </div>
        <button type="submit" class="btn btn-success btn-lg btn-block font-18">
          {{ $t("resetPassword") }}
        </button>
      </form>
    </AuthLayout>
    <template
      v-if="(validateTokenAPILoaded && !isTokenValid) || invalidQueryParams"
    >
      <div>Token not valid</div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

import AuthLayout from "../../components/admin/AuthLayout.vue";
import { useRouter, useRoute } from "vue-router";
import {
  resetPassword,
  verifyResetPasswordToken,
  resetClientPassword,
  verifyClientResetPasswordToken,
} from "../../core/api/auth-service";
import Toast from "../../components/common/Toast.vue";
import { toastMessage } from "../../core/utils";
import i18n from "@/i18n";

export default {
  name: "ResetPassword",
  components: {
    AuthLayout,
    Toast,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const validateTokenAPILoaded = ref(false);
    const invalidQueryParams = ref(false);
    const isTokenValid = ref(false);
    const resetPasswordAPILoaded = ref(false);
    const resetPasswordFormSubmitted = ref(false);
    const passwordForm = ref({
      password: "",
      passwordConfirmation: "",
    });
    const errors = ref("");
    const userId = route.query.id;
    const token = route.query.token;

    onMounted(() => {
      if (!userId || !token) {
        invalidQueryParams.value = true;
        return;
      }

      const validateToken = () => {
        if (router.currentRoute.value.path === "/admin/reset-password") {
          verifyResetPasswordToken(userId, token)
            .then((res) => {
              if (res.status === 200) {
                isTokenValid.value = true;
              }
              validateTokenAPILoaded.value = true;
            })
            .catch((err) => {
              console.error(err);
              isTokenValid.value = false;
              validateTokenAPILoaded.value = true;
            });
        }
        if (router.currentRoute.value.path === "/client/reset-password") {
          verifyClientResetPasswordToken(userId, token)
            .then((res) => {
              if (res.status === 200) {
                isTokenValid.value = true;
              }
              validateTokenAPILoaded.value = true;
            })
            .catch((err) => {
              console.error(err);
              isTokenValid.value = false;
              validateTokenAPILoaded.value = true;
            });
        }
      };

      validateToken();
    });
    const submitResetPasswordForm = () => {
      if (resetPasswordFormSubmitted.value) {
        return;
      }
      resetPasswordFormSubmitted.value = true;
      if (router.currentRoute.value.path === "/admin/reset-password") {
        resetPassword({ ...passwordForm.value, token })
          .then((res) => {
            if (res.status === 200) {
              toastMessage(
                i18n.global.t("Success"),
                i18n.global.t(res.data.data.message)
              );
              resetPasswordAPILoaded.value = true;
            }
            resetPasswordFormSubmitted.value = false;
          })
          .then(() => {
            setTimeout(() => {
              router.push({ name: "Login" });
            }, 5000);
          })
          .catch((err) => {
            errors.value = i18n.global.t(err.response.data?.errors?.[0]?.title);
            resetPasswordAPILoaded.value = true;
            resetPasswordFormSubmitted.value = false;
          });
      }
      if (router.currentRoute.value.path === "/client/reset-password") {
        resetClientPassword({ ...passwordForm.value, token })
          .then((res) => {
            if (res.status === 200) {
              toastMessage(
                i18n.global.t("Success"),
                i18n.global.t(res.data.data.message)
              );
              resetPasswordAPILoaded.value = true;
            }
            resetPasswordFormSubmitted.value = false;
          })
          .then(() => {
            setTimeout(() => {
              router.push({ name: "ClientLogin" });
            }, 5000);
          })
          .catch((err) => {
            errors.value = i18n.global.t(err.response.data?.errors?.[0]?.title);
            resetPasswordAPILoaded.value = true;
            resetPasswordFormSubmitted.value = false;
          });
      }
    };

    return {
      errors,
      validateTokenAPILoaded,
      isTokenValid,
      invalidQueryParams,
      passwordForm,
      submitResetPasswordForm,
    };
  },
};
</script>