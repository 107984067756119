<template>
  <div
    v-show="toastVisible"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    style="
      position: fixed;
      right: 30px;
      top: 30px;
      z-index: 2;
      box-shadow: 0 0 10px #e1e1e1;
      background: #fff;
      min-width: 200px;
    "
    id="simple-toasts"
  >
    <div class="toast-header">
      <span class="text-lg d-flex align-items-center">
        <i class="mdi mdi-checkbox-marked-circle text-success"></i>
      </span>
      <span class="toast-title mr-auto pl-2">{{ toastMessage.title }}</span>
    </div>
    <div class="toast-body">{{ toastMessage.message }}</div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { store } from "../../core/store";

export default {
  name: "Toast",
  setup() {
    const toastMessage = ref({
      title: "",
      message: "",
    });
    const toastVisible = ref(false);

    watchEffect(() => {
      if (
        store.getters.toastMessage.title &&
        store.getters.toastMessage.message
      ) {
        toastMessage.value = store.getters.toastMessage;
        toastVisible.value = true;
        setTimeout(() => {
          store.dispatch("updateMessage", {
            title: "",
            message: "",
          });
          toastVisible.value = false;
        }, 5000);
      }
    });

    return {
      toastMessage,
      toastVisible,
    };
  },
};
</script>